import axiosClient from "./axiosCilent";

const shopApi = {
  getListShop: () => {
    const url = "/api/v1/get-list-shop";
    return axiosClient.get(url);
  },
};

export default shopApi;
