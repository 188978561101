import { useEffect, useRef } from "react";

const VideoPlayer = ({ currentUrl }: any) => {
  const videoRef = useRef<any>(null);
  useEffect(() => {
    if (videoRef.current && currentUrl) {
      videoRef.current.load();
    }
  }, [currentUrl, videoRef]);
  return (
    <video ref={videoRef} width="100%" controls preload="true">
      <source src={currentUrl} type="video/mp4"></source>
    </video>
  );
};

export default VideoPlayer;
