export const TECHNOLOGY_SOLUTIONS = [
  {
    tilte: "Nhận hàng không tiếp xúc",
    description: "Nhận hàng qua tủ SMART BOX, không cần gặp mặt shipper",
    src: "https://static.wixstatic.com/media/d0c6c1_1d9aec3c7eb84faba4ec2f24fd0ba338~mv2.png/v1/fill/w_243,h_136,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ICON-14.png",
    width: "65%",
    height: "114",
  },
  {
    tilte: "Nói không với gián đoạn",
    description:
      "Không muốn nghe điện thoại của shipper khi đang bận? Không hối thúc, SMART BOX chỉ báo qua tin nhắn",
    src: "https://static.wixstatic.com/media/d0c6c1_cc4f8cb5fb38463b99bd41bafca1d2a4~mv2.png/v1/crop/x_0,y_31,w_2811,h_2135/fill/w_188,h_143,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ICON-16.png",
    width: "65%",
    height: "114",
  },
  {
    tilte: "Nhận hàng bất cứ lúc nào",
    description:
      "Chủ động lựa chọn giờ nhận hàng, cấp quyền mở khóa lấy hàng cho người nhận mọi lúc",
    src: "https://static.wixstatic.com/media/d0c6c1_82b61f466bc843b3ae37d403834b889a~mv2.png/v1/crop/x_212,y_0,w_2742,h_2187/fill/w_153,h_123,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ICON-37.png",
    width: "65%",
    height: "114",
  },
  {
    tilte: "Bảo mật thông tin",
    description: "Nhận hàng tại tủ gần nhất, không cần tiết lộ địa chỉ cá nhân",
    src: "https://static.wixstatic.com/media/d0c6c1_4c506f98bdab4676a114c9ad87872087~mv2.png/v1/crop/x_89,y_0,w_2988,h_2187/fill/w_154,h_114,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ICON-09.png",
    width: "65%",
    height: "114",
  },
];

export const MULTI_SECTOR_PARTNERS = [
  {
    tilte: "Đối tác vận chuyển",
    description:
      "Đơn vị vận chuyển là đối tác quan trọng trong quy trình vận hành xuyên suốt của SMART BOX, đảm bảo hàng hóa được giao chính xác, đúng thời gian tới tủ được yêu cầu.",
    src: "https://static.wixstatic.com/media/d0c6c1_dee8897a845d4839979476891c726390~mv2.png/v1/crop/x_0,y_15,w_3988,h_2208/fill/w_300,h_169,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ICON-21.png",
    width: "60%",
    height: "135",
  },
  {
    tilte: "Đối tác E-commerce",
    description:
      "SMART BOX mang đến thêm nhiều lựa chọn ưu việt hơn cho khách hàng, góp phần kích thích mua sắm, tiếp cận tới nhiều tệp khách hàng có nhu cầu đặc biệt của các đơn vị    e-commerce.",
    src: "https://static.wixstatic.com/media/d0c6c1_0d39a0f7f7a9453e973f84fcbcd5ee1c~mv2.png/v1/fill/w_195,h_179,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ICON-13.png",
    width: "60%",
    height: "135",
  },
  {
    tilte: "Đối tác điểm đặt",
    description:
      "Cung cấp địa điểm giao nhận hàng hóa thông qua tủ khóa SMART BOX, đối tác điểm đặt hợp tác cùng SMART BOX trong việc tạo ra không gian an toàn và thuận tiện cho người sử dụng.",
    src: "https://static.wixstatic.com/media/d0c6c1_44390e4714e24d809f0658e316215412~mv2.png/v1/fill/w_134,h_188,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ICON-09.png",
    width: "60%",
    height: "135",
  },
];
