import { EditOutlined } from "@ant-design/icons";
import { Col, Input, Row } from "antd";
import { Fragment, useEffect, useRef, useState } from "react";

const EditCellText = ({
  dataCell,
  keyRow,
  setDataTable,
  keyValue,
  dependentKeyFunc = () => {},
  disabled = false,
  isTextArea = false,
}: any) => {
  const [enableEditCell, setEnableEditCell] = useState(false);
  const inputRef = useRef<any>(null);

  const handelChangeValue = (event: any) => {
    setDataTable((preDataTable: any) =>
      preDataTable.map((ele: any) =>
        ele?.id !== keyRow
          ? ele
          : {
              ...ele,
              [keyValue]: event.target.value,
              ...dependentKeyFunc(event.target.value),
            }
      )
    );
    setEnableEditCell((value) => !value);
  };

  useEffect(() => {
    if (enableEditCell && inputRef.current) {
      inputRef.current.focus();
    }
  }, [enableEditCell]);

  return (
    <Fragment>
      {enableEditCell && !disabled ? (
        isTextArea ? (
          <Input.TextArea
            ref={inputRef}
            defaultValue={dataCell}
            onPressEnter={handelChangeValue}
            onBlur={handelChangeValue}
          />
        ) : (
          <Input
            ref={inputRef}
            defaultValue={dataCell}
            onPressEnter={handelChangeValue}
            onBlur={handelChangeValue}
          />
        )
      ) : (
        <Row justify="space-between">
          <Col xs={!disabled ? 20 : 24}>
            <div>{dataCell}</div>
          </Col>
          {!disabled && (
            <EditOutlined
              onClick={() => setEnableEditCell((value) => !value)}
              style={{ color: "blue" }}
            />
          )}
        </Row>
      )}
    </Fragment>
  );
};

export default EditCellText;
