import "./styles/index.css";

import AdminPage from "./views/adminPage";
import LandingPage from "./views/landingPage";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();

  return location?.pathname.startsWith("/admin") ? (
    <div className="container">
      <AdminPage />
    </div>
  ) : (
    <div className="container">
      <LandingPage />
    </div>
  );
}

export default App;
