import { Select } from "antd";

const EditCellSelect = ({
  dataCell,
  keyRow,
  setDataTable,
  keyValue,
  options,
  disabled = false,
  width = 125,
}: any) => {
  const handelChangeValue = (value: any) => {
    setDataTable((preDataTable: any) =>
      preDataTable.map((ele: any) =>
        ele?.id !== keyRow ? ele : { ...ele, [keyValue]: value }
      )
    );
  };

  return (
    <Select
      disabled={disabled}
      style={{ width: width }}
      onChange={handelChangeValue}
      options={options}
      value={dataCell}
    />
  );
};

export default EditCellSelect;
