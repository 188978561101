import { Spin } from "antd";

const LoadingContainer = () => {
  const styleLoadingContainer: any = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(16, 16, 16, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={styleLoadingContainer}>
      <Spin size="large" style={{ color: "yellow" }} />
    </div>
  );
};

export default LoadingContainer;
