import { useEffect, useState } from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { MENU_ITEMS } from "../../routes/admin";
import { useLocation, useNavigate } from "react-router-dom";

const MenuComponent = () => {
  const [current, setCurrent] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const onClick: MenuProps["onClick"] = (e) => {
    navigate(`${e.key}`);
  };

  useEffect(() => {
    const { pathname } = location;
    setCurrent(pathname);
  }, [location]);

  return (
    <Menu
      theme="light"
      onClick={onClick}
      selectedKeys={[current]}
      mode="inline"
      items={MENU_ITEMS}
      style={{
        backgroundColor: "#ffffff",
      }}
    />
  );
};

export default MenuComponent;
