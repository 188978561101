/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Row, Space, Table, message } from "antd";
import { Fragment, useEffect, useState } from "react";
import EditCellText from "../../../components/common/EditCellText";
import EditCellSelect from "../../../components/common/EditCellSelect";
import { toast } from "react-toastify";
import { isEmpty, isString, orderBy, uniqueId } from "lodash";
import { useAdminContext } from "../../../stores/contexts/AdminContext";
import bannerApi from "../../../services/bannerApi";
import UploadFile from "../../../components/common/UploadFile";

const PUBLIC_STATUS_OPTIONS = [
  {
    value: "0",
    label: "Unpublished",
  },
  {
    value: "1",
    label: "Published",
  },
];

const BannerManagement: React.FC = (props) => {
  const { setLoadingPage } = useAdminContext();
  const [dataInit, setDataInit] = useState<any>([]);
  const [reload, setReload] = useState<boolean>(false);

  const handleAddLocal = () => {
    const newData = {
      id: uniqueId("local_id_"),
      no: dataInit.length + 1,
      name: "",
      status: "0",
      url: "",
      isNew: true,
    };
    const formatDataTable = [newData, ...dataInit]?.map(
      (ele: any, index: any) => ({ ...ele, no: index + 1 })
    );
    setDataInit(formatDataTable);
  };

  const handleDelete = async (record: any) => {
    if (record.isNew) {
      setDataInit((preDataTable: any) =>
        preDataTable
          .filter((ele: any) => ele.id !== record.id)
          ?.map((ele: any, index: any) => ({ ...ele, no: index + 1 }))
      );
    } else {
      try {
        setLoadingPage(true);
        const response: any = await bannerApi.deleteBanner({ id: record.id });
        if (response.success) {
          setReload(true);
          toast.success(response?.message + "!!!", { autoClose: 2000 });
        }
      } catch (error: any) {
      } finally {
        setLoadingPage(false);
      }
    }
  };

  const handleAddServer = async (record: any) => {
    if (!record.name) {
      message.error("Shop Name is required!!!");
      return;
    }
    if (isEmpty(record.url)) {
      message.error("Image Link is required!!!");
      return;
    }
    const formData = new FormData();
    formData.append("file", record.url);
    formData.append("name", record.name);
    formData.append("status", record.status);
    try {
      setLoadingPage(true);
      const response: any = await bannerApi.addBanner(formData);
      if (response.success) {
        setReload(true);
        toast.success(response?.message + "!!!", { autoClose: 2000 });
      }
    } catch (error: any) {
    } finally {
      setLoadingPage(false);
    }
  };

  const handleUpdateServer = async (record: any) => {
    if (!record.name) {
      message.error("Shop Name is required!!!");
      return;
    }
    if (isEmpty(record.url)) {
      message.error("Image/Video Link is required!!!");
      return;
    }
    const formData = new FormData();
    formData.append("id", record.id);
    formData.append("name", record.name);
    formData.append("status", record.status);
    if (!isString(record.url)) {
      formData.append("file", record.url);
    }
    try {
      setLoadingPage(true);
      const response: any = await bannerApi.updateBanner(formData);
      if (response.success) {
        toast.success(response?.message + "!!!", { autoClose: 2000 });
      }
    } catch (error: any) {
    } finally {
      setLoadingPage(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoadingPage(true);
      const response = await bannerApi.getBanners();
      const formatDataTable = orderBy(response.data, ["status"], ["desc"]).map(
        (ele: any, index: any) => ({
          id: ele.id,
          no: index + 1,
          name: ele.name,
          status: ele.status,
          url: ele.url,
          isNew: false,
        })
      );
      setLoadingPage(false);
      setReload(false);
      setDataInit(formatDataTable);
    } catch (error: any) {
      toast.error(error.data.error + "!!!", { autoClose: 2000 });
      setLoadingPage(false);
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "4%",
    },
    {
      title: "Shop Name",
      dataIndex: "name",
      key: "name",
      width: "23%",
      render: (_: any, record: any) => (
        <EditCellText
          dataCell={record?.name}
          keyRow={record?.id}
          keyValue="name"
          setDataTable={setDataInit}
        />
      ),
    },
    {
      title: "Public Status",
      dataIndex: "status",
      key: "status",
      width: "23%",
      render: (_: any, record: any) => (
        <EditCellSelect
          dataCell={record?.status}
          keyRow={record?.id}
          keyValue="status"
          setDataTable={setDataInit}
          options={PUBLIC_STATUS_OPTIONS}
        />
      ),
    },
    {
      title: "Image/Video",
      dataIndex: "url",
      key: "url",
      width: "20%",
      render: (_: any, record: any) => (
        <Space size="middle">
          <UploadFile
            setDataTable={setDataInit}
            keyRow={record?.id}
            keyValue="url"
            url={record?.url}
            accessFileTypes={["image/jpeg", "image/png", "video/mp4"]}
          />
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "30%",
      render: (record: any) => (
        <Row justify="center">
          <Space size="middle">
            {record.isNew ? (
              <Button onClick={() => handleAddServer(record)} type="primary">
                Add
              </Button>
            ) : (
              <Button onClick={() => handleUpdateServer(record)} type="primary">
                Update
              </Button>
            )}
            <Button type="primary" danger onClick={() => handleDelete(record)}>
              Delete
            </Button>
          </Space>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
    return () => setDataInit([]);
  }, []);

  useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [reload]);

  return (
    <Fragment>
      <Button
        onClick={handleAddLocal}
        type="primary"
        style={{ marginBottom: 16 }}
      >
        Add New Ads
      </Button>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dataInit}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "40", "50"],
        }}
      />
    </Fragment>
  );
};

export default BannerManagement;
