import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import { HomePageRoutes } from "../../routes/landing";
import { Route, Routes } from "react-router-dom";

const LandingPage = () => {
  return (
    <div className="container">
      <div className="header">
        <Header />
      </div>
      <Routes>
        {HomePageRoutes.map((item) => (
          <Route path={item.path} element={item.element} />
        ))}
      </Routes>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
