import { Col, Row, Button } from "antd";
import Title from "antd/es/typography/Title";
import {
  PhoneOutlined,
  MailOutlined,
  UserOutlined,
  SolutionOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import "../../../styles/support.css";

const Support = () => {
  return (
    <div className="content">
      <Row justify="center">
        <Col xs={20} md={14}>
          <Title level={2} style={{ textAlign: "center", marginTop: 15 }}>
            Liên hệ với chúng tôi
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={20} md={12}>
          <Title level={5} style={{ textAlign: "center" }}>
            Khung thời gian hỗ trợ: <br />
            (Từ 6h00 tới 22h00 từ Thứ 2 đến Chủ Nhật)
          </Title>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 10 }}>
        <Col xs={20} md={10}>
          <Row>
            <Col className="col-contact" span={12}>
              <PhoneOutlined className="icon-support" />
            </Col>
            <Col className="col-contact" span={12}>
              <MailOutlined className="icon-support" />
            </Col>
            <Col className="col-contact" span={12}>
              Đường dây nóng
            </Col>
            <Col className="col-contact" span={12}>
              Email hỗ trợ
            </Col>
            <Col className="col-contact" span={12}>
              123456789
            </Col>
            <Col className="col-contact" span={12}>
              example.com
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 40 }}>
        <Col xs={20} md={14}>
          <Title level={2} style={{ textAlign: "center", marginTop: 15 }}>
            Liên hệ hợp tác
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24} md={18}>
          <Row>
            <Col className="col-contact" xs={24} md={12} lg={6}>
              <Row>
                <Col span={24} className="col-contact">
                  <UserOutlined className="icon-support" />
                </Col>
                <Col span={24} className="col-contact">
                  Khách hàng cá nhân
                </Col>
                <Col span={24} className="col-contact">
                  example.com
                </Col>
              </Row>
            </Col>
            <Col className="col-contact" xs={24} md={12} lg={6}>
              <Row>
                <Col span={24} className="col-contact">
                  <SolutionOutlined className="icon-support" />
                </Col>
                <Col span={24} className="col-contact">
                  Đối tác truyền thông
                </Col>
                <Col span={24} className="col-contact">
                  example.com
                </Col>
              </Row>
            </Col>
            <Col className="col-contact" xs={24} md={12} lg={6}>
              <Row>
                <Col span={24} className="col-contact">
                  <AuditOutlined className="icon-support" />
                </Col>
                <Col span={24} className="col-contact">
                  Khách hàng doanh nghiệp
                </Col>
                <Col span={24} className="col-contact">
                  example.com
                </Col>
              </Row>
            </Col>
            <Col className="col-contact" xs={24} md={12} lg={6}>
              <Row>
                <Col span={24} className="col-contact">
                  <SolutionOutlined className="icon-support" />
                </Col>
                <Col span={24} className="col-contact">
                  Đối tác vận hành
                </Col>
                <Col span={24} className="col-contact">
                  example.com
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 40 }} justify="center">
        <Col span={24}>
          <Title level={2} style={{ textAlign: "center", marginTop: 15 }}>
            Trung tâm hỗ trợ
          </Title>
        </Col>
        <Col xs={24} lg={12}>
          <Row justify="space-between">
            <Col className="col-contact" xs={24} md={8}>
              <Button
                className="button-color"
                style={{
                  paddingRight: 30,
                  paddingLeft: 30,
                  height: 40,
                  borderRadius: 40,
                  color: "white",
                }}
              >
                Chính sách quyền riêng tư
              </Button>
            </Col>
            <Col className="col-contact" xs={24} md={8}>
              <Button
                className="button-color"
                style={{
                  paddingRight: 30,
                  paddingLeft: 30,
                  height: 40,
                  borderRadius: 40,
                  color: "white",
                }}
              >
                Điều khoản dịch vụ
              </Button>
            </Col>
            <Col className="col-contact" xs={24} md={8}>
              <Button
                className="button-color"
                style={{
                  paddingRight: 30,
                  paddingLeft: 30,
                  height: 40,
                  borderRadius: 40,
                  color: "white",
                }}
              >
                Danh mục hàng hóa cấm
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={16} lg={8}>
          <Row justify="center">
            <Col
              className="col-contact"
              xs={24}
              md={12}
              style={{ marginTop: 15 }}
            >
              <Button
                className="button-color"
                style={{
                  paddingRight: 30,
                  paddingLeft: 30,
                  height: 40,
                  borderRadius: 40,
                  color: "white",
                }}
              >
                Chính sách bồi thường
              </Button>
            </Col>
            <Col className="col-contact" md={12} style={{ marginTop: 15 }}>
              <Button
                className="button-color"
                style={{
                  paddingRight: 30,
                  paddingLeft: 30,
                  height: 40,
                  borderRadius: 40,
                  color: "white",
                }}
              >
                Quy cách đóng gói
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Support;
