import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import queryString from "query-string";

// Local host BASE_URL
// export const BASE_URL = "http://127.0.0.1:8000";

// Release BASE_URL
export const BASE_URL = "https://hutrng.io.vn";

export const ACCES_TOKEN = "accessToken";

const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "x-api-key":
      "C33QEy8QRQJYMkIItCsaUaeK9TfS4Zpulg6bjcrRVvsEc5pVWPOStMnGb5LO4h7h",
  },
  paramsSerializer: (params: any) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    const customHeaders: any = {};
    const accessToken = sessionStorage.getItem(ACCES_TOKEN);
    if (accessToken) {
      customHeaders.Authorization = "Bearer " + accessToken;
    }
    return {
      ...config,
      headers: {
        ...customHeaders, // auto attach token
        ...config.headers, // but you can override for some requests
      },
    };
  },
  function (error: AxiosError) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    return response.data;
  },
  function (error: AxiosError) {
    return Promise.reject(error.response?.data);
  }
);

export default axiosClient;
