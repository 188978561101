import { MenuProps } from "antd";
import AdminLogin from "../../views/adminPage/login";
import BannerManagement from "../../views/adminPage/bannerManagement";
import VoucherManagement from "../../views/adminPage/voucherManagement";
import UserManagement from "../../views/adminPage/userManagement";

export const ROUTE_URL = {
  LOGIN: "/admin/login",
  HOME: "/admin/manage-video-ads",
  MANAGE_VIDEO_ADS: "/admin/manage-video-ads",
  MANAGE_VOUCHER: "/admin/manage-voucher",
  MANAGE_USER: "/admin/manage-user",
};

export const MENU_ITEMS: MenuProps["items"] = [
  {
    label: "Banner Management",
    key: ROUTE_URL.MANAGE_VIDEO_ADS,
  },
  {
    label: "Voucher Management",
    key: ROUTE_URL.MANAGE_VOUCHER,
  },
  {
    label: "User Management",
    key: ROUTE_URL.MANAGE_USER,
  },
];

export const AdminRoutes = [
  {
    path: ROUTE_URL.LOGIN,
    element: <AdminLogin />,
  },
  {
    path: ROUTE_URL.HOME,
    element: <BannerManagement />,
  },
  {
    path: ROUTE_URL.MANAGE_VIDEO_ADS,
    element: <BannerManagement />,
  },
  {
    path: ROUTE_URL.MANAGE_VOUCHER,
    element: <VoucherManagement />,
  },
  {
    path: ROUTE_URL.MANAGE_USER,
    element: <UserManagement />,
  },
];
