export const TECHNOLOGY_SOLUTIONS = [
  {
    tilte: "Khách hàng",
    description:
      "Đem tới trải nghiệm dịch vụ xuất sắc, tối ưu cho cuộc sống hàng ngày và giúp khách hàng tránh được sự lãng phí về thời gian, công sức.",
    src: "https://static.wixstatic.com/media/d0c6c1_364279c730134b468e072d5fa86d22a0~mv2.png/v1/fill/w_71,h_71,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/d0c6c1_364279c730134b468e072d5fa86d22a0~mv2.png",
    width: "75",
    height: "75",
  },
  {
    tilte: "Nhân viên",
    description:
      "Kiến tạo không gian và môi trường làm việc chuyên nghiệp, không ngừng hoàn thiện, đổi mới để nhân viên được phát triển toàn diện, mang lại nhiều giá trị cho bản thân.",
    src: "https://static.wixstatic.com/media/d0c6c1_364279c730134b468e072d5fa86d22a0~mv2.png/v1/fill/w_71,h_71,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/d0c6c1_364279c730134b468e072d5fa86d22a0~mv2.png",
    width: "75",
    height: "75",
  },
  {
    tilte: "Xã hội",
    description:
      "Nâng cấp chất lượng dịch vụ giao nhận, đóng góp những giá trị thực về vật chất, sức khỏe, sự an toàn và cải thiện môi trường phục vụ xã hội.",
    src: "https://static.wixstatic.com/media/d0c6c1_364279c730134b468e072d5fa86d22a0~mv2.png/v1/fill/w_71,h_71,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/d0c6c1_364279c730134b468e072d5fa86d22a0~mv2.png",
    width: "75",
    height: "75",
  },
];
