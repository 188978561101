export const GENERAL_RULE = [
  "Dịch vụ Tủ khóa thông minh SMART APP là dịch vụ tự phục vụ có thu tiền. Trước khi sử dụng Dịch vụ, Người sử dụng dịch vụ phải điền thông tin Gói hàng theo yêu cầu và đảm bảo rằng thông tin đó là chính xác và trung thực.",
  "Trước khi sử dụng dịch vụ Tủ khóa thông minh SMART APP, Người sử dụng dịch vụ phải hoàn thành xác thực tên thật theo yêu cầu, điền thông tin cá nhân của Người gửi và Người nhận (họ tên, địa chỉ, địa điểm sử dụng Tủ khóa thông minh SMART APP, số liên lạc, ...) và đảm bảo rằng thông tin đó là chính xác. Khi thông tin thay đổi, Người sử dụng dịch vụ phải cập nhật thông tin kịp thời tại Hệ thống dịch vụ. Nếu thông tin được cung cấp không chính xác, không hợp lệ hoặc không đầy đủ và khiến Dịch vụ không thể thực hiện thành công (bao gồm cả việc không thể nhận Gói hàng đúng giờ, giao hàng sau khi nhận hàng, v.v.), Người sử dụng dịch vụ sẽ phải chịu mọi trách nhiệm liên quan.",
  "Người sử dụng dịch vụ đồng ý cung cấp thông tin nhận dạng cá nhân (bao gồm thông tin cá nhân như tên, thông tin liên lạc, số chứng minh nhân dân/căn cước công dân/hộ chiếu, v.v.) để đảm bảo cung cấp dịch vụ chính xác. Công ty cổ phần SMART APP Express cam kết rằng sẽ không tự ý tiết lộ cho bất kỳ bên thứ ba nào (ngoại trừ các đối tượng nói trên) các thông tin của Người sử dụng trừ mục đích vì lợi ích của Người sử dụng mà SMART APP xét thấy cần thiết hoặc theo quy định pháp luật.",
];

export const DEFINE = [
  "“Dịch vụ Tủ khóa thông minh SMART APP” là dịch vụ gửi, nhận, lưu trữ hàng hóa, gói hàng tại các điểm Tủ khóa thông minh do Công ty cổ phần SMART APP Express cung cấp.",
  "“SMART APP” là Công ty cổ phần SMART APP Express và/hoặc Dịch vụ Tủ khóa thông minh SMART APP và tiện ích gia tăng khác do Công ty cổ phần SMART APP Express cung cấp (tùy vào ngữ cảnh).",
  "“Điều khoản sử dụng dịch vụ” là điều khoản sử dụng dịch vụ Tủ khóa thông minh SMART APP (gọi tắt là “Điều khoản sử dụng”).",
  "“Khách hàng”/ “Người sử dụng” là cá nhân, tổ chức sử dụng dịch vụ của SMART APP; bao gồm cá nhân/tổ chức gửi/lưu trữ, cá nhân/tổ chức nhận/giao hàng hóa (việc định nghĩa Người sử dụng/Khách hàng bao gồm không hạn chế những người trực tiếp sử dụng Dịch vụ tại Tủ khóa thông minh SMART APP; người sử dụng phần mềm, website dịch vụ của SMART APP nhằm thao tác đặt, sử dụng Dịch vụ không phân biệt có/không có Tài khoản SMART APP; người nhận ủy quyền sử dụng Dịch vụ; …).",
  "“Gói hàng” là gói, kiện hàng hóa hoặc các tài sản, hiện vật khác được SMART APP chấp nhận, lưu trữ, gửi giữ, vận chuyển hợp pháp trong hệ thống Tủ khóa thông minh của SMART APP.",
  "“Đơn hàng” là yêu cầu sử dụng Dịch vụ được Người sử dụng thiết lập qua hệ thống phần mềm hoặc website dịch vụ của SMART APP có đầy đủ thông tin về Gói hàng.",
  "“Dịch vụ” là Dịch vụ tủ khóa thông minh SMART APP và dịch vụ liên quan việc lưu trữ, gửi nhận gói hàng, bao gồm nhưng không hạn chế các hoạt động tiếp nhận, lưu trữ Gói hàng bằng các phương thức khác nhau thông qua hệ thống Tủ và vận chuyển của SMART APP.",
  "“Dịch vụ gia tăng”/ “Tiện ích gia tăng” là các dịch vụ của SMART APP hoặc bên thứ ba cung cấp đi kèm việc sử dụng/không sử dụng Dịch vụ tủ khóa thông minh SMART APP.",
  "“Hệ thống”/ “Tủ khóa thông minh SMART APP”/ “Tủ”/ “Tủ SMART APP” (tùy vào từng ngữ cảnh) là hệ thống Tủ khóa thông minh SMART APP và các phần mềm ứng dụng được cài đặt trên thiết bị di động, website hoặc tại Tủ khóa thông minh mà SMART APP thiết lập cho việc sử dụng dịch vụ của khách hàng (bao gồm tạo, quản lý, trợ giúp, cập nhật thông tin, thông báo và theo dõi trạng thái của Đơn hàng; thanh toán cước dịch vụ; kiểm soát, đối chiếu dữ liệu về Gói hàng và tiền sử dụng Dịch vụ).",
  "“Ngày làm việc” là ngày dương lịch từ thứ Hai đến thứ Sáu không bao gồm thứ Bảy, Chủ Nhật và các ngày lễ, tết theo quy định pháp luật.",
  "“Tài khoản SMART APP”/ “Tài khoản” là tên người dùng và mật khẩu được sử dụng để đăng nhập vào ứng dụng SMART APP để sử dụng các Dịch vụ.",
  "“Chuyển phát” là hàng hóa thư từ, bưu kiện, tài liệu in, … được chuyển phát bởi các đơn vị vận chuyển.",
  "“Đơn vị vận chuyển” là đơn vị vận chuyển thực hiện nghiệp vụ vận chuyển, nhận, gửi hàng theo quy định pháp luật.",
  "“Ngăn tủ”/ “Hộp SMART APP”/ “Ngăn tủ SMART APP” là ngăn tủ thuộc hệ thống Tủ khóa thông minh SMART APP là bộ phận lưu trữ, gửi, nhận Gói hàng nhỏ nhất.",
  "“Gói hàng không đủ thông tin”: Các gói hàng không thể xác nhận người gửi/người nhận và/hoặc các thông tin tối thiểu để thực hiện Dịch vụ.",
  "“Công ty hợp tác” là Công ty cổ phần Infinity Valley và các tổ chức khác hợp tác cung SMART APP để cung ứng dịch vụ tủ khóa thông minh SMART APP.",
];

export const CREATE_SMART_APP_ACCOUNT = [
  "Người sử dụng truy cập website hoặc tải ứng dụng của SMART APP để tạo Tài khoản SMART APP sử dụng Dịch vụ.",
  "Người sử dụng phải đồng ý tuân thủ các Điều khoản sử dụng, Chính sách bảo mật và quyền riêng tư, Danh mục hàng hóa cấm và các chính sách và điều khoản khác của SMART APP trong từng thời kỳ.",
  "Bằng việc truy cập website, tải về và sử dụng ứng dụng của SMART APP, Người sử dụng thừa nhận và đồng ý rằng chấp thuận với các phương thức, yêu cầu, và/hoặc chính sách được quy định trong Điều khoản sử dụng này và rằng Người sử dụng đồng ý cho SMART APP thu thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân của Người sử dụng như được mô tả trong Điều khoản sử dụng này và các chính sách kèm theo.",
  "Người sử dụng được xem là đương nhiên chấp nhận và chịu sự ràng buộc của những Điều khoản sử dụng này và việc Người sử dụng dùng một phần hoặc toàn bộ các Dịch vụ/sản phẩm của SMART APP được xem là giữa Người sử dụng và SMART APP đã ký kết và thực hiện một hợp đồng dịch vụ.",
];

export const USER_INFORMATION = [
  "Người sử dụng đồng ý cung cấp cho SMART APP các thông tin đầy đủ, cập nhật và chính xác liên quan đến Người sử dụng mà SMART APP sẽ yêu cầu vào từng thời điểm để sử dụng Dịch vụ/sản phẩm của SMART APP. Người sử dụng đồng ý thông báo ngay cho SMART APP bất kỳ thay đổi nào về thông tin tài khoản Người sử dụng.",
  "Người sử dụng tuyên bố và đảm bảo rằng các thông tin của Người sử dụng và các thông tin khác được cung cấp cho SMART APP là trung thực và chính xác và chịu trách nhiệm đối với các thông tin đã cung cấp trên website hoặc ứng dụng điện thoại của SMART APP.",
];

export const ACCOUNT_MANAGEMENT = [
  "Nếu có bằng chứng hoặc SMART APP có lý do để tin rằng Người sử dụng đã đăng ký hoặc sử dụng Tài khoản SMART APP sai mục đích hoặc bất hợp pháp (bao gồm nhưng không giới hạn việc đăng ký với danh tính giả, đăng ký nhiều tài khoản mà không có lý do chính đáng, cho phép hoặc cung cấp cho người khác sử dụng tài khoản) SMART APP có thể thực hiện các biện pháp như khóa tạm thời hoặc khóa vĩnh viễn tài khoản của Người sử dụng và từ chối cung cấp Dịch vụ. Nếu Người sử dụng gây ra tổn thất cho SMART APP hoặc bên thứ ba, Người sử dụng phải chịu trách nhiệm bồi thường theo quy định pháp luật.",
  "Người sử dụng có trách nhiệm tự quản lý tài khoản trong quá trình sử dụng, không được cho mượn, cho thuê, mua bán tài khoản trong mọi trường hợp, cố ý/không cố ý để lộ thông tin Tài khoản SMART APP dẫn đến người thứ ba có thể sử dụng hoặc thực hiện các hành vi sai quy định của Điều khoản sử dụng này và quy định pháp luật. SMART APP không chịu trách nhiệm trong bất kỳ trường hợp nào đối với việc tài khoản của Người sử dụng bị trộm cắp, mất mát, tấn công mạng internet trên thiết bị của Người sử dụng, hoặc các trường hợp Người sử dụng phải biết hoặc buộc phải biết Người sử dụng phải có nghĩa vụ bảo vệ tài khoản.",
  "Người sử dụng phải thông báo ngay cho SMART APP nếu Người sử dụng mất quyền kiểm soát tài khoản của mình do trộm cắp, mất mát, gian lận hoặc bị tấn công ... SMART APP sẽ dừng dịch vụ, khóa tạm thời hoặc khóa vĩnh viễn tài khoản.",
  "Đối với các tình huống khác mà SMART APP tin rằng sẽ cản trở tính bảo mật và quản lý tài khoản, SMART APP có thể thu hồi, khóa tạm thời hoặc khóa vĩnh viễn tài khoản của Người sử dụng.",
];

export const SMART_APP_LOCKER_SERVICE = [
  "SMART APP cung ứng dịch vụ gửi, nhận, lưu trữ hàng hóa/gói hàng thông qua Hệ thống Tủ khóa thông minh SMART APP, các phần mềm, ứng dụng điện thoại, website dịch vụ và giải pháp công nghệ. SMART APP chỉ cung ứng dịch vụ kỹ thuật lưu trữ/gửi/nhận trung gian, SMART APP không đảm bảo rằng sẽ có đơn vị/tổ chức dịch vụ vận chuyển/bưu chính chuyển phát cho Người sử dụng. Người sử dụng hiểu và đồng ý rằng trường hợp Người sử dụng tự mình sử dụng dịch vụ vận chuyển/bưu chính của bên thứ ba thì SMART APP không có mối quan hệ pháp lý/hợp đồng/giao dịch với bên thứ ba.",
  "Dịch vụ của SMART APP cung ứng là Dịch vụ có thu tiền sử dụng dịch vụ.",
  "SMART APP có thể thay đổi, tạm ngừng hoặc chấm dứt một số Dịch vụ nhất định dựa trên các yếu tố từ nhu cầu thị trường, sự phát triển kinh tế hoặc trải nghiệm người dùng mà không cần sự đồng ý của Người sử dụng.",
  "SMART APP cung cấp hướng dẫn sử dụng Dịch vụ tại ứng dụng điện thoại, website của SMART APP.",
  "SMART APP cung cấp Dịch vụ toàn diện 24/24 giờ 07 (bảy) ngày trong tuần, đảm bảo lưu trữ Gói hàng của Người sử dụng. SMART APP chịu trách nhiệm cung cấp Dịch vụ cho Người sử dụng trên cơ sở Gói hàng nguyên vẹn và không hư hỏng do lỗi của SMART APP. SMART APP cung ứng Dịch vụ với nghĩa vụ cơ bản theo quy định pháp luật, nhưng không giải quyết các vấn đề liên quan đến việc bảo trì thiết bị mạng thông tin, lỗi kết nối, lỗi máy tính, liên lạc hoặc hệ thống khác, mất điện, đình công, bạo loạn, hỏa hoạn, thiên tại và các trường hợp bất khả kháng khác (SMART APP không chịu trách nhiệm trong các trường hợp này).",
  "Người sử dụng có nghĩa vụ tuân thủ Điều khoản sử dụng dịch vụ này, Danh mục hàng hóa cấm, Quy cách đóng gói hàng hóa, Chính sách bảo mật và quyền riêng tư mà SMART APP đã công khai tại website, phần mềm ứng dụng điện thoại hoặc các phương tiện truyền thông khác.",
  "SMART APP không chịu trách nhiệm và từ chối cung ứng Dịch vụ trong mọi trường hợp Người sử dụng vi phạm nghĩa vụ, trách nhiệm của Người sử dụng.",
  "SMART APP chịu trách nhiệm và bồi thường thiệt hại (đối với những lỗi do SMART APP gây ra) cho Người sử dụng theo Chính sách bồi thường được SMART APP ban hành trong từng thời kỳ và được công khai trên website, ứng dụng của SMART APP. Trường hợp Gói hàng được mua bảo hiểm trong quá trình sử dụng Dịch vụ, Người sử dụng được hưởng chính sách bảo hiểm theo hợp đồng bảo hiểm, SMART APP được miễn trừ toàn bộ trách nhiệm bồi thường trong trường hợp này.",
  "Người sử dụng hiểu và đồng ý rằng, trường hợp SMART APP phát hiện Người sử dụng vi phạm Điều khoản sử dụng dịch vụ, Danh mục hàng hóa cấm, Quy cách đóng gói hàng hóa, hoặc bất kỳ chính sách, điều khoản Dịch vụ nào; SMART APP có quyền xử lý Gói hàng ngay lập tức mà không cần báo trước cho Người sử dụng bằng các biện pháp sau (bao gồm nhưng không hạn chế):",
];

export const PRICE_TO_USE_THE_SERVICE = [
  "SMART APP công khai giá sử dụng Dịch vụ Tủ khóa thông minh SMART APP tại các trang thông tin truyền thông, website, ứng dụng của SMART APP.",
  "SMART APP có quyền điều chỉnh cách tính giá Dịch vụ, SMART APP thông báo công khai giá dịch vụ trước khi áp dụng giá dịch vụ mới.",
  "Người sử dụng phải tuân thủ quy định của SMART APP về Quy cách đóng gói hàng hóa, Danh mục hàng hóa cấm mà SMART APP ban hành. SMART APP không chịu trách nhiệm trong trường hợp Người sử dụng không thực hiện theo những hướng dẫn và chính sách của SMART APP.",
  "SMART APP cung cấp các tiện ích gia tăng có thu tiền kèm Dịch vụ, Người sử dụng có quyền chọn hoặc không chọn việc sử dụng tiện ích gia tăng.",
];

export const SERVICE_PROVISION_TERM = [
  "SMART APP cung cấp Dịch vụ tối đa không quá 72 (bảy mươi hai) giờ (“Thời hạn cung cấp dịch vụ”) đối với 01 (một) Ngăn tủ kể từ thời điểm Người sử dụng bắt đầu sử dụng Dịch vụ (“Thời điểm bắt đầu dịch vụ”) đến thời điểm Người sử dụng kết thúc sử dụng Dịch vụ hoặc đến 72 (bảy mươi hai) giờ (“Thời điểm kết thúc dịch vụ”- tùy vào thời điểm nào đến trước).",
  "SMART APP ngừng cung cấp Dịch vụ tại Ngăn tủ đã cung cấp Dịch vụ đủ 72 (bảy mươi hai) giờ và không gia hạn thời hạn cung cấp Dịch vụ, Người sử dụng có nghĩa vụ lấy và/hoặc thu hồi/nhận Gói hàng tại Ngăn tủ và trả tiền Dịch vụ (nếu có).",
  "Trường hợp Gói hàng được lưu trữ/gửi tại Ngăn tủ quá 72 (bảy mươi hai) giờ SMART APP sẽ có thông báo chính thức về việc SMART APP ngừng cung cấp Dịch vụ và Gói hàng đã được gửi/lưu trữ quá Thời hạn cung cấp dịch vụ bằng các phương tiện thông tin (SMS, cuộc gọi thoại, thông báo tại ứng dụng điện thoại, ...) do SMART APP quyết định.",
  "Sau 72 (bảy mươi hai) giờ kể từ Thời điểm bắt đầu dịch vụ, nếu Người sử dụng không thu hồi/lấy/nhận Gói hàng, SMART APP sẽ phân loại Gói hàng là “Gói hàng quá hạn”, tiến hành di chuyển Gói hàng quá hạn và xử lý theo quy định của SMART APP. Người sử dụng phải tuân thủ quy định của SMART APP trong trường hợp có nhu cầu thu hồi/lấy/nhận Gói hàng quá hạn tại Ngăn tủ SMART APP.",
  "SMART APP có quyền thay đổi, điều chỉnh và quyết định nội dung và Thời gian cung cấp dịch vụ. SMART APP sẽ thông báo trước khi cung ứng Dịch vụ để Người sử dụng được biết.",
];

export const HANDLING_OVERDUE_PACKAGES = [
  "Kể từ Thời điểm kết thúc dịch vụ SMART APP không chịu trách nhiệm đối với Gói hàng quá hạn, Gói hàng quá hạn sẽ không được bảo hiểm (nếu có) và bồi thường trong mọi trường hợp sau Thời điểm kết thúc dịch vụ.",
  "SMART APP sẽ thông báo tới Người sử dụng để trả tiền Dịch vụ (nếu có) và thu hồi/nhận/lấy Gói hàng quá hạn. SMART APP sẽ tạm thời di chuyển Gói hàng quá hạn tại Ngăn tủ và lưu trữ tại kho lưu trữ do SMART APP bố trí theo chính sách của SMART APP trong từng thời kỳ. Người sử dụng chịu trách nhiệm đối với chi phí lưu trữ tại kho hoặc chi phí vận chuyển (nếu có).",
  "Người sử dụng có quyền tự đến kho lưu trữ của SMART APP để trả tiền Dịch vụ và thu hồi/lấy/nhận Gói hàng quá hạn hoặc đề nghị SMART APP hỗ trợ vận chuyển (có thu tiền dịch vụ) đối với Gói hàng quá hạn. SMART APP có quyền quyết định trong việc hỗ trợ vận chuyển/không vận chuyển Gói hàng quá hạn.",
  "Người sử dụng đồng ý SMART APP (có quyền nhưng không có nghĩa vụ) lưu trữ Gói hàng quá hạn tại kho lưu trữ tối đa không quá 06 (sáu) tháng (“Thời hạn lưu trữ”) kể từ Thời điểm kết thúc dịch vụ. SMART APP không chịu trách nhiệm về Gói hàng quá hạn đối với những vấn đề sau: (i) tính toàn vẹn, hoặc chống hư hỏng; (ii) hết hạn sử dụng, đảm bảo nhiệt độ, độ ẩm, chất lượng môi trường lưu trữ; (iii) chống mất, thất lạc, … của Gói hàng quá hạn.",
  "Người sử dụng hiểu và đồng ý không điều kiện rằng, SMART APP có quyền kiểm tra, bóc, mở Gói hàng quá hạn nhằm mục đích phân loại lưu trữ, bảo quản mà không chịu trách nhiệm về tính toàn vẹn, không hư hỏng, thiệt hại của Gói hàng quá hạn.",
  "Trường hợp SMART APP phát hiện Gói hàng quá hạn thuộc Danh mục hàng hóa cấm của SMART APP hoặc bị cấm mua bán, lưu trữ, vận chuyển theo quy định pháp luật; SMART APP có quyền xử lý theo chính sách của SMART APP hoặc theo quy định pháp luật.",
  "Người sử dụng hiểu và đồng ý không khiếu nại, khởi kiện, tố cáo, yêu cầu bồi thường thiệt hại trong mọi trường hợp: (i) Gói hàng quá hạn trong Ngăn tủ do lỗi của Người sử dụng; (ii) Kết thúc thời hạn dịch vụ mà Người sử dụng không thu hồi/lấy/nhận Gói hàng; (iii) SMART APP di chuyển và lưu trữ Gói hàng quá hạn; (iv) Gói hàng quá hạn thất lạc, mất trong Thời hạn lưu trữ…",
  "Người sử dụng đồng ý từ bỏ mọi quyền sở hữu và/hoặc vận chuyển và toàn bộ các quyền truy đòi, yêu cầu bồi thường, khởi kiện, tố cáo, … đối với Gói hàng quá hạn. SMART APP được hiểu là bên thứ ba ngay tình trong mọi trường hợp. SMART APP có toàn quyền xử lý (bao gồm nhưng không hạn chế các biện pháp tiêu hủy, bán đấu giá, bán thanh lý, tiếp nhận quyền sở hữu, sử dụng Gói hàng quá để bù đắp tiền sử dụng Dịch vụ, …) khi hết Thời hạn lưu trữ. Đối với những Gói hàng quá hạn thuộc Danh mục hàng hóa cấm của SMART APP hoặc bị cấm mua bán, lưu trữ, vận chuyển theo quy định pháp luật, SMART APP có quyền xử lý ngay lập tức bằng mọi biện pháp theo quy định của SMART APP trong từng thời kỳ và quy định pháp luật mà không cần báo trước (bao gồm nhưng không giới hạn biện pháp tiêu hủy, bán đấu giá, bán thanh lý, giao cho cơ quan nhà nước có thẩm quyền, gửi tin báo tội phạm, …).",
];

export const SUSPENSION_INTERRUPTION_TERMINATION = [
  "SMART APP có quyền chấm dứt Dịch vụ tại Thời điểm kết thúc dịch vụ mà không gia hạn hoặc tiếp tục cung cấp Dịch vụ, Người sử dụng vẫn có nghĩa vụ trả tiền Dịch vụ đối với những Dịch vụ đã sử dụng (nếu có). SMART APP quyết định việc tiếp tục cung cấp, gia hạn Dịch vụ trong từng thời kỳ.",
  "Người sử dụng hiểu và đồng ý rằng SMART APP có quyền đơn phương tạm ngừng hoặc chấm dứt cung cấp Dịch vụ theo quyết định của SMART APP khi xét thấy việc cung cấp Dịch vụ không phù hợp, ảnh hưởng đến hoạt động, quyền lợi của SMART APP hoặc Khách hàng.",
  "Người sử dụng hiểu và đồng ý rằng các Dịch vụ của SMART APP có thể bị tạm ngừng, gián đoạn hoặc chấm dứt do lỗi hệ thống, các cuộc tấn công mạng internet, website, ứng dụng điện tử, …, các hành động hoặc yêu cầu của cơ quan nhà nước có thẩm quyền, sự kiện bất khả kháng hoặc lý do khác mà SMART APP xét thấy cần phải tạm ngừng, chấm dứt Dịch vụ nhằm đảm bảo quyền lợi của SMART APP và Khách hàng. Tùy theo lý do và mức độ của việc tạm dừng hoặc gián đoạn, SMART APP sẽ nhanh chóng phản hồi và tiếp tục cung cấp dịch vụ trong thời gian sớm nhất.",
];

export const OTHER_REGULATIONS_ON_USE_OF_THE_SERVICE = [
  "Người sử dụng sử dụng các Dịch vụ của SMART APP, ngoài việc tuân thủ Điều khoản sử dụng này và các yêu cầu, chính sách công khai của SMART APP, Người sử dụng cũng phải tuân thủ các quy định của pháp luật có liên quan. SMART APP có quyền nhưng không có nghĩa vụ giải thích, thông báo, nhắc nhở việc tuân thủ các quy định của SMART APP và quy định pháp luật.",
  "Người sử dụng phải chịu trách nhiệm bồi thường nếu Người sử dụng hoặc đơn vị vận chuyển Người sử dụng sử dụng gây ra tổn thất cho SMART APP hoặc các bên liên quan do vi phạm Điều khoản sử dụng dịch vụ này hoặc các Chính sách, điều khoản khác của SMART APP.",
];

export const SMART_APP_STRICTLY_PROHIBITED = [
  "Gây nguy hiểm cho an ninh quốc gia, làm lộ bí mật nhà nước, phá hoại quyền lực nhà nước, phá hoại đoàn kết dân tộc.",
  "Thiệt hại đến danh dự và lợi ích quốc gia.",
  "Kích động hận thù, kỳ thị và phá hoại đoàn kết dân tộc.",
  "Phá hoại các chính sách tôn giáo dân tộc, cổ súy các tôn giáo và mê tín dị đoan phong kiến.",
  "Tin đồn thất thiệt, gây rối trật tự xã hội và phá hoại sự ổn định xã hội.",
  "Truyền bá nội dung bất hợp pháp hoặc không phù hợp với thuần phong mỹ tục Việt Nam như: nội dung tục tĩu, khiêu dâm, cờ bạc, bạo lực, giết người, khủng bố hoặc xúi giục tội phạm.",
  "Xúc phạm, vu khống người khác, xâm phạm quyền và lợi ích hợp pháp của người khác.",
  "Xâm phạm các quyền và lợi ích hợp pháp của người khác như quyền sở hữu trí tuệ và bí mật kinh doanh.",
  "Thông tin hư cấu, che giấu sự thật để gây hiểu lầm hoặc lừa dối người khác.",
  "Công bố, truyền tải và phổ biến thông tin quảng cáo xấu và thư rác.",
  "Các tình huống khác mà pháp luật và các quy định cấm.",
];

export const INFORMATION_MANAGE = [
  "Nếu Người sử dụng cần truy vấn, thay đổi hoặc xóa thông tin trên tài khoản SMART APP, Người sử dụng có thể làm theo lời nhắc hoặc thông báo trên ứng dụng khách hàng của SMART APP hoặc liên hệ với SMART APP.",
  "SMART APP khuyến nghị Người sử dụng nên liên hệ với SMART APP trước khi thay đổi hoặc xóa thông tin tài khoản. SMART APP sẽ xác minh thông tin liên quan của Người sử dụng theo quy định của pháp luật tại thời điểm đó và quy định của SMART APP. Người sử dụng có trách nhiệm hợp tác để cung cấp thông tin mới nhất, đúng sự thật và đầy đủ.",
];

export const COMPENSATION_IN_THE_CASES = [
  "Đặt các Gói hàng có trọng lượng vượt quá trọng lượng cho phép hoặc có thông số kỹ thuật vượt quá kích thước của Ngăn tủ.",
  "Lưu trữ hàng hóa vi phạm chính sách của SMART APP. (Xem thêm tại Danh mục hàng hóa cấm lưu trữ tại tủ đựng đồ thông minh SMART APP).",
  "Đặt các Gói hàng rỗng, Gói hàng không đủ thông tin hoặc các Gói hàng bất thường khác vào Ngăn tủ.",
  "Gửi nhiều Gói hàng khác nhau trong cùng một Ngăn tủ.",
  "Có hành vi cố ý xâm phạm đối với Tủ khóa thông minh, bao gồm nhưng không giới hạn việc va đập, phá hoại, làm xước màn hình LCD, làm hỏng ống kính giám sát (camera), mở hoặc đóng cửa không đúng cách, can thiệp phần mềm, ...",
  "Các hành vi bị cấm khác theo quy định pháp luật.",
];

export const OWNERSHIP_OF_INTELLECTUAL_PROPERTY = [
  "Trừ khi được SMART APP tuyên bố hoặc cho phép, tất cả các sản phẩm, công nghệ, hình ảnh, dữ liệu hình ảnh, âm thanh, video, thiết kế bố cục và thông tin, nội dung hoặc tài liệu khác được hiển thị trên ứng dụng SMART APP (bao gồm bản quyền tính năng ứng dụng điện tử, bản quyền tính năng hệ điều hành tủ thông minh, quyền thương hiệu, quyền sáng chế, …) thuộc về SMART APP.",
  "Không có bất kỳ điều khoản, nội dung nào được coi là SMART APP chuyển giao một phần hoặc toàn bộ bất kỳ/tất cả các quyền sử dụng bản quyền tính năng ứng dụng trên điện thoại, bản quyền tính năng hệ điều hành tủ khóa thông minh, quyền thương hiệu, quyền sáng chế, … thuộc về SMART APP nếu không có sự cho phép rõ ràng bằng văn bản của SMART APP.",
];

export const SEND_NOTIFICATION = [
  "SMART APP gửi cho Người sử dụng thông báo thông qua thông tin liên hệ mà Người sử dụng cung cấp (do SMART APP quyết định). Thông báo được gửi bằng phương thức điện tử, bao gồm nhưng không giới hạn ở các thông báo của Người sử dụng trên ứng dụng SMART APP, SMS, email, ... Thông báo điện tử sẽ được coi là gửi thành công sau khi hệ thống xác nhận đã gửi.",
  "Trường hợp thông tin đó được gửi bằng văn bản, thông báo được coi là đã được gửi thành công vào ngày làm việc thứ 05 (năm) sau ngày bưu phẩm bắt đầu được gửi theo địa chỉ liên lạc.",
];

export const CHANGES_TO_TERMS_OF_SERVICE = [
  "SMART APP có thể thay đổi, sửa đổi, bổ sung nội dung Điều khoản sử dụng dịch vụ, các chính sách của SMART APP, giá Dịch vụ và các điều khoản Dịch vụ khác dựa trên điều kiện kinh doanh thực tế mà không cần báo trước hoặc sự đồng ý của Người sử dụng.",
  "SMART APP sẽ sử dụng ứng dụng dành cho khách hàng của SMART APP hoặc các phương pháp tương tự hợp lý khác để thông báo công khai. Sau 03 (ba) ngày làm việc kể từ ngày SMART APP thông báo, nếu Người sử dụng tiếp tục hoạt động, sử dụng Dịch vụ (bao gồm nhưng không giới hạn ở việc đồng ý nhấp chuột, thanh toán hoặc tiếp tục sử dụng Dịch vụ, ...), Người sử dụng được coi là đồng ý với những thay đổi trên.",
  "SMART APP có quyền chuyển giao toàn bộ hoặc một phần quyền và nghĩa vụ của Điều khoản sử dụng này cho các công ty hợp tác của SMART APP. SMART APP thông báo trước cho Người sử dụng tối đa không quá 03 (ba) ngày làm việc.",
];

export const COMPLAINTS_AND_CLAIMS = [
  "Trong quá trình sử dụng Dịch vụ, Người sử dụng có thể thực hiện khiếu nại Dịch vụ theo đến số điện thoại/kênh tiếp nhận khiếu nại được SMART APP cung cấp tại ứng dụng, website hoặc Tủ SMART APP.",
  "SMART APP xác nhận và phản hồi khiếu nại trong thời hạn 01 (một) ngày làm việc kể từ thời điểm tiếp nhận khiếu nại. Trường hợp cần hồ sơ xác mình, SMART APP có quyền yêu cầu cung cấp hồ sơ đến các kênh tiếp nhận chính thức của SMART APP.",
  "SMART APP tiến hành thẩm định và phản hồi thông tin khiếu nại đối với hồ sơ/tài liệu do Khách hàng cung cấp trong vòng 03 (ba) ngày làm việc. SMART APP có thể yêu cầu Người sử dụng bổ sung, sửa đổi hồ sơ/tài liệu khiếu nại, trong trường hợp này SMART APP sẽ xử lý thông tin sau khi Người sử dụng cung cấp đủ hồ sơ trong thời hạn 07 (bảy) ngày làm việc.",
  "Trường hợp có yêu cầu về bồi thường, Người sử dụng thực hiện theo Điều khoản bồi thường hoặc chính sách bảo hiểm Dịch vụ trong từng thời kỳ.",
];
