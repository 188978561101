/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Modal, Row, Space, Table, message } from "antd";
import { Fragment, useEffect, useRef, useState } from "react";
import EditCellText from "../../../components/common/EditCellText";
import EditCellSelect from "../../../components/common/EditCellSelect";
import { toast } from "react-toastify";
import { orderBy, uniqueId } from "lodash";
import { useAdminContext } from "../../../stores/contexts/AdminContext";
import userApi from "../../../services/userApi";
import en_US from "../../../locales/en-US";

const USER_TYPE_OPTIONS = [
  {
    value: "user",
    label: "User",
  },
  {
    value: "shop",
    label: "Shop Owner",
  },
];

const ACTIVE_STATUS_OPTIONS = [
  {
    value: 1,
    label: "Active",
  },
  {
    value: 0,
    label: "Inactive",
  },
];

const UserManagement: React.FC = (props) => {
  const { setLoadingPage } = useAdminContext();
  const [dataInit, setDataInit] = useState<any>([]);
  const [reload, setReload] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState<any>(null);
  const formPassword = useRef<any>(null);

  const showModal = (record: any) => {
    setIsModalOpen(true);
    setCurrentRecord(record);
  };

  const handleOk = () => {
    const passwordAccount =
      formPassword.current.getFieldValue("passwordAccount");
    if (!passwordAccount) {
      message.error("Password Account is required!!!");
      return;
    }
    if (!currentRecord.name) {
      message.error("Name is required!!!");
      return;
    }
    if (!currentRecord.email) {
      message.error("Email is required!!!");
      return;
    }
    if (!currentRecord.mobileNumber) {
      message.error("Mobile Number is required!!!");
      return;
    }
    setIsModalOpen(false);
    handleAddServer(passwordAccount);
    formPassword.current.resetFields(["passwordAccount"]);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    formPassword.current.resetFields(["passwordAccount"]);
  };

  const handleAddLocal = () => {
    const newData = {
      id: uniqueId("local_id_"),
      no: dataInit.length + 1,
      name: "",
      type: "user",
      email: "",
      address: "",
      mobileNumber: "",
      isActive: 1,
      isNew: true,
    };
    const formatDataTable = [newData, ...dataInit]?.map(
      (ele: any, index: any) => ({ ...ele, no: index + 1 })
    );
    setDataInit(formatDataTable);
  };

  const handleAddServer = async (passwordAccount: string) => {
    try {
      const payload = {
        name: currentRecord.name,
        email: currentRecord.email,
        mobile_number: currentRecord.mobileNumber,
        password: passwordAccount,
        address: currentRecord.address,
      };
      setLoadingPage(true);
      const response: any = await userApi.addUser(payload);
      if (response.success) {
        setReload(true);
        toast.success(response?.message + "!!!", { autoClose: 2000 });
      }
    } catch (error: any) {
      toast.error(error.data.error + "!!!", { autoClose: 2000 });
    } finally {
      setLoadingPage(false);
      setCurrentRecord(null);
    }
  };

  const handleUpdateServer = async (record: any) => {
    if (!record.name) {
      message.error("Name is required!!!");
      return;
    }
    try {
      const payload = {
        userId: record.userId,
        type: record.type,
        name: record.name,
        address: record.address,
        isActive: record.isActive,
      };
      setLoadingPage(true);
      const response: any = await userApi.updateUser(payload);
      if (response.success) {
        setReload(true);
        toast.success(response?.message + "!!!", { autoClose: 2000 });
      }
    } catch (error: any) {
      toast.error(error?.data?.error + "!!!", { autoClose: 2000 });
    } finally {
      setLoadingPage(false);
    }
  };

  const handleDelete = async (record: any) => {
    setDataInit((preDataTable: any) =>
      preDataTable
        .filter((ele: any) => ele.id !== record.id)
        ?.map((ele: any, index: any) => ({ ...ele, no: index + 1 }))
    );
  };

  const fetchData = async () => {
    try {
      setLoadingPage(true);
      const response = await userApi.getListUser();
      const formatDataTable = orderBy(response.data, ["status"], ["desc"]).map(
        (ele: any, index: any) => ({
          no: index + 1,
          id: ele.id,
          userId: ele.user_id,
          name: ele.name,
          type: ele.type || "user",
          email: ele.email,
          address: ele.address,
          mobileNumber: ele.mobile_number,
          isActive: ele.is_active,
          isNew: false,
        })
      );
      setLoadingPage(false);
      setReload(false);
      setDataInit(formatDataTable);
    } catch (error: any) {
      toast.error(error.data.error + "!!!", { autoClose: 2000 });
      setLoadingPage(false);
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "4%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
      render: (_: any, record: any) => (
        <EditCellText
          dataCell={record?.name}
          keyRow={record?.id}
          keyValue="name"
          setDataTable={setDataInit}
        />
      ),
    },
    {
      title: "User Type",
      dataIndex: "type",
      key: "type",
      width: "8%",
      render: (_: any, record: any) => (
        <EditCellSelect
          dataCell={record?.type}
          keyRow={record?.id}
          keyValue="type"
          setDataTable={setDataInit}
          options={USER_TYPE_OPTIONS}
        />
      ),
    },
    {
      title: "Active Status",
      dataIndex: "isActive",
      key: "isActive",
      width: "8%",
      render: (_: any, record: any) => (
        <EditCellSelect
          dataCell={record?.isActive}
          keyRow={record?.id}
          keyValue="isActive"
          setDataTable={setDataInit}
          options={ACTIVE_STATUS_OPTIONS}
        />
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "20%",
      render: (_: any, record: any) => (
        <EditCellText
          dataCell={record?.address}
          keyRow={record?.id}
          keyValue="address"
          setDataTable={setDataInit}
        />
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "10%",
      render: (_: any, record: any) => (
        <EditCellText
          dataCell={record?.email}
          keyRow={record?.id}
          keyValue="email"
          setDataTable={setDataInit}
          disabled={!record?.isNew}
        />
      ),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      width: "8%",
      render: (_: any, record: any) => (
        <EditCellText
          dataCell={record?.mobileNumber}
          keyRow={record?.id}
          keyValue="mobileNumber"
          setDataTable={setDataInit}
          disabled={!record?.isNew}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "6%",
      render: (record: any) => (
        <Row justify="center">
          <Space size="middle">
            {record.isNew ? (
              <Fragment>
                <Button onClick={() => showModal(record)} type="primary">
                  Add
                </Button>
                <Button
                  type="primary"
                  danger
                  onClick={() => handleDelete(record)}
                >
                  Delete
                </Button>
              </Fragment>
            ) : (
              <Button onClick={() => handleUpdateServer(record)} type="primary">
                Update
              </Button>
            )}
          </Space>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
    return () => setDataInit([]);
  }, []);

  useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [reload]);

  return (
    <Fragment>
      <Button
        onClick={handleAddLocal}
        type="primary"
        style={{ marginBottom: 16 }}
      >
        Add New User
      </Button>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dataInit}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "40", "50"],
        }}
        scroll={{ x: "calc(1000px + 50%)" }}
      />
      <Modal
        title="Please enter account password before creating"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form autoComplete="off" ref={formPassword}>
          <Form.Item
            name="passwordAccount"
            rules={[
              {
                required: true,
                message: en_US["global.tips.enterPasswordMessageAccount"],
              },
            ]}
          >
            <Input
              onBlur={() => undefined}
              placeholder={en_US["global.tips.enterPasswordMessageAccount"]}
              type="password"
              required
            />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default UserManagement;
