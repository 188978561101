import CardImage from "../../../components/common/CardImage";
import { Button, Col, Image, Row } from "antd";
import Title from "antd/es/typography/Title";
import { MULTI_SECTOR_PARTNERS, TECHNOLOGY_SOLUTIONS } from "./constants";
import { useNavigate } from "react-router-dom";
import { ROUTE_URL } from "../../../routes/landing";

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <div className="content">
      <Row justify="center">
        <Col xs={22} md={16}>
          <Title level={2} style={{ textAlign: "center", marginTop: 15 }}>
            SMART BOX MÔ HÌNH GIAO NHẬN HÀNG HÓA QUA TỦ KHÓA THÔNG MINH
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={22} md={16}>
          <Title level={5} style={{ textAlign: "center", marginTop: -10 }}>
            Nhận hàng bất cứ khi nào mà không cần gặp trực tiếp shipper SMART
            BOX đảm bảo hàng hóa an toàn cho đến khi bạn tới lấy
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24} md={20} lg={18}>
          <Image
            style={{ width: "100%" }}
            src="https://static.wixstatic.com/media/d0c6c1_d2032a94128f482782ab1a08af2b0902~mv2.png/v1/crop/x_236,y_196,w_2084,h_1039/fill/w_1158,h_578,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/Xanh%20luc%20khong%20nen%20(1).png"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }} justify="center">
        <Button
          className="button-color"
          style={{
            paddingRight: 30,
            paddingLeft: 30,
            height: 40,
            borderRadius: 40,
            color: "white",
            fontSize: 17,
          }}
          onClick={() => navigate(ROUTE_URL.PRODUCT)}
        >
          Khám phá ngay
        </Button>
      </Row>
      <Row style={{ marginTop: 60 }} justify="center">
        <Col xs={22} md={14}>
          <Title level={2} style={{ textAlign: "center", marginTop: 15 }}>
            Thay đổi hoàn toàn trải nghiệm khi mua sắm cùng SMART BOX
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={20} md={10}>
          <Title level={5} style={{ textAlign: "center", marginTop: -10 }}>
            Gửi hàng, nhận hàng chỉ bằng một ứng dụng trên điện thoại
          </Title>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }} justify="center">
        <Col xs={24} md={22} lg={20}>
          <Image
            width="100%"
            src="https://static.wixstatic.com/media/d0c6c1_230509b0d2974139ae1c0a7d92f481bc~mv2.png/v1/crop/x_0,y_412,w_2500,h_952/fill/w_1336,h_509,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/HD%20T%E1%BA%A2I%20APP%201%20KO%20BG-01.png"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 60 }} justify="center">
        <Col xs={22} md={14}>
          <Title level={2} style={{ textAlign: "center", marginTop: 15 }}>
            Giải pháp công nghệ ngành logistics mang hiệu quả đến cho cộng đồng
          </Title>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 20 }}>
        <Col span={22}>
          <Row gutter={[35, 35]}>
            {TECHNOLOGY_SOLUTIONS.map((item) => (
              <Col xs={24} md={12} lg={6}>
                <CardImage
                  styles={{ backgroundColor: "#fffcf8", border: "none" }}
                  tilte={item.tilte}
                  description={item.description}
                  width="100%"
                  height="100%"
                  imageHeight={item.height}
                  imageWidth={item.width}
                  src={item.src}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 30 }} justify="center">
        <Col xs={22} md={14}>
          <Title level={2} style={{ textAlign: "center", marginTop: 15 }}>
            Trở thành một phần trong mạng lưới đối tác đa lĩnh vực của SMART BOX
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={22} md={12}>
          <Title level={5} style={{ textAlign: "center", marginTop: -10 }}>
            Thúc đẩy mua sắm và thanh toán trực tuyến, gia tăng giá trị và trải
            nghiệm khách hàng, hãy để SMART BOX đồng hành cùng bạn
          </Title>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 35 }}>
        <Col span={18}>
          <Row gutter={[35, 35]}>
            {MULTI_SECTOR_PARTNERS.map((item) => (
              <Col xs={24} lg={8}>
                <CardImage
                  styles={{
                    backgroundColor: "#fffcf8",
                    borderRadius: 40,
                    boxShadow: "var(--shd,0 0px 5px rgba(0,0,0,.6))",
                  }}
                  tilte={item.tilte}
                  description={item.description}
                  width="100%"
                  height="100%"
                  imageHeight={item.height}
                  imageWidth={item.width}
                  src={item.src}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 50 }} justify="center">
        <Button
          className="button-color"
          style={{
            paddingRight: 35,
            paddingLeft: 35,
            height: 40,
            borderRadius: 40,
            color: "white",
            fontSize: 17,
          }}
          onClick={() => navigate(ROUTE_URL.ADVERTISING_PARTNER)}
        >
          Xem thêm
        </Button>
      </Row>
      <Row justify="center" style={{ marginTop: 50 }}>
        <Col xs={22} md={14}>
          <Title level={2} style={{ textAlign: "center", marginTop: 15 }}>
            Đăng ký tài khoản và trải nghiệm dịch vụ của SMART BOX
          </Title>
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }} justify="center">
        <Button
          className="button-color"
          style={{
            paddingRight: 35,
            paddingLeft: 35,
            height: 40,
            borderRadius: 40,
            color: "white",
            fontSize: 17,
          }}
          onClick={() =>
            navigate(ROUTE_URL.PRODUCT, { state: { isMoveToQr: true } })
          }
        >
          Đăng ký ngay
        </Button>
      </Row>
    </div>
  );
};

export default HomePage;
