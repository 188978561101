/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DatePicker, Row, Space, Table, message } from "antd";
import { Fragment, useEffect, useState } from "react";
import EditCellText from "../../../components/common/EditCellText";
import { isEmpty, uniqueId } from "lodash";
import { useAdminContext } from "../../../stores/contexts/AdminContext";
import UploadFile from "../../../components/common/UploadFile";
import EditCellSelect from "../../../components/common/EditCellSelect";
import voucherApi from "../../../services/voucherApi";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import shopApi from "../../../services/shopApi";

const DATE_FORMAT_INPUT = "YYYY-MM-DD hh:mm:ss";

const PUBLIC_STATUS_OPTIONS = [
  {
    value: "0",
    label: "Unpublished",
  },
  {
    value: "1",
    label: "Published",
  },
];

const VoucherManagement: React.FC = (props) => {
  const { setLoadingPage } = useAdminContext();
  const [dataInit, setDataInit] = useState<any>([]);
  const [dataShop, setDataShop] = useState<any>([]);

  const [reload, setReload] = useState<boolean>(false);

  const handleAddLocal = () => {
    const newData = {
      id: uniqueId("local_id_"),
      no: dataInit.length + 1,
      shopName: "",
      title: "",
      description: "",
      fullDescription: "",
      status: "0",
      url: "",
      quantity: "",
      remaining: "",
      validStartTime: null,
      validEndTime: null,
      ownerUserId: null,
      isNew: true,
    };
    const formatDataTable = [newData, ...dataInit]?.map(
      (ele: any, index: any) => ({ ...ele, no: index + 1 })
    );
    setDataInit(formatDataTable);
  };

  const handleDelete = async (record: any) => {
    if (record.isNew) {
      setDataInit((preDataTable: any) =>
        preDataTable
          .filter((ele: any) => ele.id !== record.id)
          ?.map((ele: any, index: any) => ({ ...ele, no: index + 1 }))
      );
    } else {
      try {
        setLoadingPage(true);
        const response: any = await voucherApi.deleteBanner({
          voucher_id: record.id,
        });
        if (response.success) {
          setReload(true);
          toast.success(response?.message + "!!!", { autoClose: 2000 });
        }
      } catch (error: any) {
        toast.error(error.data.error + "!!!", { autoClose: 2000 });
      } finally {
        setLoadingPage(false);
      }
    }
  };

  const handleAddServer = async (record: any) => {
    const formData = new FormData();
    if (!record.shopName) {
      message.error("Shop Name is required!!!");
      return;
    }
    if (!record.title) {
      message.error("Title is required!!!");
      return;
    }
    if (!record.description) {
      message.error("Description is required!!!");
      return;
    }
    if (!record.fullDescription) {
      message.error("Full Description is required!!!");
      return;
    }
    if (!record.ownerUserId) {
      message.error("Shop Account is required!!!");
      return;
    }
    if (isEmpty(record.url)) {
      message.error("Image Link is required!!!");
      return;
    }
    if (!record.quantity) {
      message.error("Quantity is required!!!");
      return;
    }
    if (!record.validStartTime) {
      message.error("Valid Start Time is required!!!");
      return;
    }
    if (!record.validEndTime) {
      message.error("Valid End Time is required!!!");
      return;
    }
    formData.append("shopName", record.shopName);
    formData.append("title", record.title);
    formData.append("description", record.description);
    formData.append("fullDescription", record.fullDescription);
    formData.append("status", record.status);
    formData.append("quantity", record.quantity);
    formData.append("remaining", record.remaining);
    formData.append("validStartTime", record.validStartTime);
    formData.append("validEndTime", record.validEndTime);
    formData.append("file", record.url);
    formData.append("ownerUserId", record.ownerUserId);
    try {
      setLoadingPage(true);
      const response: any = await voucherApi.addVourcher(formData);
      if (response.success) {
        setReload(true);
      }
    } catch (error: any) {
      toast.error(error.data.error + "!!!", { autoClose: 2000 });
    } finally {
      setLoadingPage(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoadingPage(true);
      const response = await voucherApi.getVouchers();
      const formatDataTable = response.data.map((ele: any, index: any) => ({
        id: ele.voucher_id,
        no: index + 1,
        shopName: ele.shop_name,
        title: ele.title,
        description: ele.description,
        fullDescription: ele.full_description,
        status: ele.status,
        url: ele.url_image,
        quantity: ele.quantity,
        remaining: ele.remaining,
        validStartTime: ele.valid_start_time,
        validEndTime: ele.valid_end_time,
        ownerUserId: ele.owner_user_id,
        isNew: false,
      }));
      setLoadingPage(false);
      setReload(false);
      setDataInit(formatDataTable);
    } catch (error: any) {
      toast.error(error.data.error + "!!!", { autoClose: 2000 });
      setLoadingPage(false);
    }
  };

  const fetchDataListShop = async () => {
    try {
      setLoadingPage(true);
      const response = await shopApi.getListShop();
      const formatDataShop = response.data.map((ele: any, index: any) => ({
        id: ele.id,
        label: ele.name,
        value: ele.user_id,
      }));
      setLoadingPage(false);
      setReload(false);
      setDataShop(formatDataShop);
    } catch (error: any) {
      console.log("error.data", error.data);

      toast.error(error.message + "!!!", { autoClose: 2000 });
      setLoadingPage(false);
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "1%",
    },
    {
      title: "Shop Name",
      dataIndex: "shopName",
      key: "shopName",
      width: "5%",
      render: (_: any, record: any) => (
        <EditCellText
          dataCell={record?.shopName}
          keyRow={record?.id}
          keyValue="shopName"
          setDataTable={setDataInit}
          disabled={!record.isNew}
        />
      ),
    },
    {
      title: "Shop Account",
      dataIndex: "ownerUserId",
      key: "ownerUserId",
      width: "5%",
      render: (_: any, record: any) => (
        <EditCellSelect
          dataCell={record?.ownerUserId}
          keyRow={record?.id}
          keyValue="ownerUserId"
          setDataTable={setDataInit}
          options={dataShop}
          disabled={!record.isNew}
          width={150}
        />
      ),
    },
    {
      title: "Public Status",
      dataIndex: "status",
      key: "status",
      width: "5%",
      render: (_: any, record: any) => (
        <EditCellSelect
          dataCell={record?.status}
          keyRow={record?.id}
          keyValue="status"
          setDataTable={setDataInit}
          options={PUBLIC_STATUS_OPTIONS}
          disabled={!record.isNew}
        />
      ),
    },
    {
      title: "Image",
      dataIndex: "url",
      key: "url",
      width: "11%",
      render: (_: any, record: any) => (
        <Space size="middle">
          <UploadFile
            setDataTable={setDataInit}
            keyRow={record?.id}
            keyValue="url"
            url={record?.url}
            accessFileTypes={["image/jpeg", "image/png"]}
            disabled={!record.isNew}
          />
        </Space>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "4%",
      render: (_: any, record: any) => (
        <EditCellText
          dataCell={record?.quantity}
          keyRow={record?.id}
          keyValue="quantity"
          setDataTable={setDataInit}
          dependentKeyFunc={(newValue: any) => ({
            remaining: newValue,
          })}
          disabled={!record.isNew}
        />
      ),
    },
    {
      title: "Remaining",
      dataIndex: "remaining",
      key: "remaining",
      width: "4%",
    },
    {
      title: "Valid Start Time",
      dataIndex: "validStartTime",
      key: "validStartTime",
      width: "7%",
      render: (_: any, record: any) => (
        <DatePicker
          defaultValue={
            !record.isNew
              ? dayjs(record.validStartTime, DATE_FORMAT_INPUT)
              : undefined
          }
          onChange={(value) =>
            setDataInit((preDataTable: any) =>
              preDataTable.map((ele: any) =>
                ele.id === record.id
                  ? { ...ele, validStartTime: value?.format(DATE_FORMAT_INPUT) }
                  : ele
              )
            )
          }
          format={DATE_FORMAT_INPUT}
          disabled={!record.isNew}
          showTime
        />
      ),
    },
    {
      title: "Valid End Time",
      dataIndex: "validEndTime",
      key: "validEndTime",
      width: "7%",
      render: (_: any, record: any) => (
        <DatePicker
          defaultValue={
            !record.isNew
              ? dayjs(record.validEndTime, DATE_FORMAT_INPUT)
              : undefined
          }
          onChange={(value) =>
            setDataInit((preDataTable: any) =>
              preDataTable.map((ele: any) =>
                ele.id === record.id
                  ? { ...ele, validEndTime: value?.format(DATE_FORMAT_INPUT) }
                  : ele
              )
            )
          }
          format={DATE_FORMAT_INPUT}
          disabled={!record.isNew}
          showTime
        />
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "8%",
      render: (_: any, record: any) => (
        <EditCellText
          dataCell={record?.title}
          keyRow={record?.id}
          keyValue="title"
          setDataTable={setDataInit}
          disabled={!record.isNew}
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "12%",
      render: (_: any, record: any) => (
        <EditCellText
          dataCell={record?.description}
          keyRow={record?.id}
          keyValue="description"
          setDataTable={setDataInit}
          disabled={!record.isNew}
          isTextArea
        />
      ),
    },
    {
      title: "Full Description",
      dataIndex: "fullDescription",
      key: "fullDescription",
      width: "22%",
      render: (_: any, record: any) => (
        <EditCellText
          dataCell={record?.fullDescription}
          keyRow={record?.id}
          keyValue="fullDescription"
          setDataTable={setDataInit}
          disabled={!record.isNew}
          isTextArea
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "9%",
      render: (record: any) => (
        <Row justify="center">
          <Space size="middle">
            {record.isNew && (
              <Button onClick={() => handleAddServer(record)} type="primary">
                Publish
              </Button>
            )}
            <Button type="primary" danger onClick={() => handleDelete(record)}>
              Delete
            </Button>
          </Space>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
    return () => setDataInit([]);
  }, []);

  useEffect(() => {
    fetchDataListShop();
    return () => setDataInit([]);
  }, []);

  useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [reload]);

  return (
    <Fragment>
      <Button
        onClick={handleAddLocal}
        type="primary"
        style={{ marginBottom: 16 }}
      >
        Add New Voucher
      </Button>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dataInit}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "40", "50"],
        }}
        scroll={{ x: "calc(2500px + 50%)" }}
      />
    </Fragment>
  );
};

export default VoucherManagement;
