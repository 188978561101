import axiosClient from "./axiosCilent";

const voucherApi = {
  addVourcher: (params: any) => {
    const url = "/api/v1/post-voucher";
    return axiosClient.post(url, params);
  },
  getVouchers: () => {
    const url = "/api/v1/get-voucher";
    return axiosClient.get(url);
  },
  deleteBanner: (params: any) => {
    const url = "/api/v1/delete-voucher";
    return axiosClient.post(url, params);
  },
};

export default voucherApi;
