import { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Image, Row, Upload, message } from "antd";
import { isString } from "lodash";
import { BASE_URL } from "../../services/axiosCilent";
import VideoPlayer from "./VideoPlayer";

const UploadFile = ({
  url,
  setDataTable,
  keyRow,
  keyValue,
  accessFileTypes = [],
  disabled = false,
}: any) => {
  const [currentUrl, setCurrentUrl] = useState<any>(null);
  const [fileList, setFileList] = useState<any>([]);

  const uploadProps = {
    beforeUpload: (file: any) => {
      const isValidFile = accessFileTypes.includes(file.type);
      let stringTypes = "";
      for (let index = 0; index < accessFileTypes.length; index++) {
        const element = accessFileTypes[index];
        stringTypes +=
          index === 0
            ? element.split("/")[1].toUpperCase()
            : `/${element.split("/")[1].toUpperCase()}`;
      }
      if (!isValidFile && accessFileTypes.length) {
        message.error(`You can only upload ${stringTypes} file!`);
      }
      if (isValidFile) {
        setFileList([file]);
        setDataTable((preDataTable: any) =>
          preDataTable.map((ele: any) =>
            ele?.id !== keyRow ? ele : { ...ele, [keyValue]: file }
          )
        );
      }
      return false;
    },
    showUploadList: false,
    fileList,
  };

  useEffect(() => {
    if (fileList.length && fileList[0] && !isString(fileList[0])) {
      setCurrentUrl(URL.createObjectURL(fileList[0]));
    }
  }, [fileList]);

  useEffect(() => {
    setCurrentUrl(url);
    setFileList([url]);
  }, [url]);

  return (
    <Row>
      {currentUrl && (
        <Col xs={24}>
          {(isString(url) &&
            (url.endsWith("jpeg") ||
              url.endsWith("png") ||
              url.endsWith("jpg"))) ||
          (!isString(url) && url.type !== "video/mp4") ? (
            <Image
              src={isString(url) ? `${BASE_URL}${currentUrl}` : currentUrl}
              alt=""
              style={{ width: "100%" }}
            />
          ) : (
            <VideoPlayer
              currentUrl={
                isString(url) ? `${BASE_URL}${currentUrl}` : currentUrl
              }
            />
          )}
        </Col>
      )}
      <Col xs={24} style={currentUrl ? { marginTop: 20 } : {}}>
        <Row justify="center">
          <Upload {...uploadProps} disabled={disabled}>
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Row>
      </Col>
    </Row>
  );
};

export default UploadFile;
