import CardImage from "../../../components/common/CardImage";
import { Col, Row, Image } from "antd";
import Title from "antd/es/typography/Title";
import { TECHNOLOGY_SOLUTIONS } from "./constants";
import "../../../styles/aboutUs.css";

const AboutSmartBox = () => {
  return (
    <div className="content">
      <Row justify="center">
        <Col span={18}>
          <Title level={2} className="small-title">
            Câu chuyện bắt đầu <br />
            từ mong muốn mang lại lợi ích cho cộng đồng
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={18}>
          <Title level={5} className="small-title" italic>
            "Một ý tưởng được phát triển nhằm nâng cao chất lượng dịch vụ
            logistic. <br />
            Một thời điểm khi xã hội buộc phải hạn chế tiếp xúc cá nhân. <br />
            Một ý tưởng hay và một thời điểm cần giải pháp hiệu quả, SMART BOX
            ra đời!"
          </Title>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 30 }}>
        <Col>
          <Image
            style={{ width: "100%" }}
            src="https://static.wixstatic.com/media/d0c6c1_d0f62e33db25462cab91628ad4f658e2~mv2.png/v1/fill/w_565,h_377,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/d0c6c1_c306572f8f3349938274e8e53e5089bb~mv2.png"
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col span={18}>
          <Title level={2} className="big-title">
            Sứ Mệnh
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={18}>
          <Title level={5} className="small-title">
            Trở thành doanh nghiệp "3 Hài Lòng" với những giá trị tích cực cống
            hiến cho:
          </Title>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 20 }}>
        <Col xs={24} lg={16}>
          <Row justify="space-between">
            {TECHNOLOGY_SOLUTIONS.map((item) => (
              <Col xs={24} md={8} style={{ marginTop: 25 }}>
                <Row justify={"center"}>
                  <CardImage
                    styles={{
                      backgroundColor: "#fffcf8",
                      borderRadius: 40,
                      boxShadow: "var(--shd,0 0px 5px rgba(0,0,0,.6))",
                    }}
                    tilte={item.tilte}
                    description={item.description}
                    width="90%"
                    height="375"
                    imageHeight={item.height}
                    imageWidth={item.width}
                    src={item.src}
                  />
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={16}>
          <Title level={2} className="big-title" style={{ marginTop: 50 }}>
            Tầm Nhìn
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={20} lg={14}>
          <Row justify="center">
            <Title level={5} className="small-title">
              Trở thành công ty cung cấp dịch vụ giao - nhận hàng hóa qua hệ
              thống tủ khóa thông minh với quy mô lớn nhất, cung cấp chất lượng
              dịch vụ tốt nhất tại thị trường Việt Nam. Phấn đấu đến năm 2026
              đạt độ phủ hơn 20.000 tủ trên toàn quốc.
            </Title>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AboutSmartBox;
