import { useEffect, type FC, useState } from "react";
import { Button, Form, Col, Row, Input } from "antd";
import "../../../styles/admin/login.css";
import type { LoginParams } from "../../../interface/user/login";
import CardImage from "../../../components/common/CardImage";
import logoImg from "../../../images/logo.png";
import en_US from "../../../locales/en-US/";
import userApi from "../../../services/userApi";
import { isEmpty } from "lodash";
import { useAdminContext } from "../../../stores/contexts/AdminContext";
import { toast } from "react-toastify";
import { USER_INFO_JSON_NAME } from "..";
import { ACCES_TOKEN } from "../../../services/axiosCilent";

const AdminLogin: FC = () => {
  const [loginInfor, setLoginInfor] = useState({});
  const { setLoadingPage } = useAdminContext();

  useEffect(() => {
    if (!isEmpty(loginInfor)) {
      (async () => {
        try {
          setLoadingPage(true);
          const response: any = await userApi.login(loginInfor);
          setLoadingPage(false);
          sessionStorage.setItem(
            USER_INFO_JSON_NAME,
            JSON.stringify(response?.data)
          );
          sessionStorage.setItem(ACCES_TOKEN, response?.data?.token);
          toast.success(response?.message + "!!!", { autoClose: 2000 });
        } catch (error: any) {
          toast.error(error.data.error + "!!!", { autoClose: 2000 });
        } finally {
          setLoadingPage(false);
        }
      })();
    }
  }, [loginInfor, setLoadingPage]);

  const onFinished = (value: LoginParams) => {
    setLoginInfor(value);
  };

  return (
    <div className="login-page">
      <Row>
        <Col span={24}>
          <Row justify={"center"}>
            <Col>
              <CardImage
                styles={{
                  backgroundColor: "#fffcf8",
                  borderRadius: 20,
                  boxShadow: "var(--shd,0 0px 5px rgba(0,0,0,.6))",
                  textAlign: "left",
                  marginBottom: 20,
                }}
                tilte="LOGIN TO SMARTBOX ADMIN PAGE"
                src={logoImg}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify={"center"}>
            <Col>
              <Form<LoginParams>
                onFinish={onFinished}
                className="login-page-form"
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: en_US["global.tips.enterUsernameMessage"],
                    },
                  ]}
                >
                  <Input
                    placeholder={en_US["global.tips.enterUsernameMessage"]}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: en_US["global.tips.enterPasswordMessage"],
                    },
                  ]}
                >
                  <Input
                    type="password"
                    placeholder={en_US["global.tips.enterPasswordMessage"]}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="button-color login-page-button"
                  >
                    <p> {en_US["global.tips.login"]}</p>
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AdminLogin;
