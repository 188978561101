import {
  AuditOutlined,
  SolutionOutlined,
  SoundOutlined,
  UserOutlined,
} from "@ant-design/icons";

export const ADVERTISING_PARTNER = [
  {
    tilte: "Đối tác vận chuyển",
    description:
      "Đơn vị vận chuyển là đối tác quan trọng trong quy trình vận hành xuyên suốt của SMART BOX, đảm bảo hàng hóa được giao chính xác, đúng thời gian tới tủ được được yêu cầu.",
    src: "https://static.wixstatic.com/media/d0c6c1_dee8897a845d4839979476891c726390~mv2.png/v1/crop/x_0,y_15,w_3988,h_2208/fill/w_225,h_126,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ICON-21.png",
    width: "60%",
    height: "126",
  },
  {
    tilte: "Đối tác điểm đặt",
    description:
      "Cung cấp địa điểm giao nhận hàng hóa thông qua tủ khóa SMART BOX, đối tác điểm đặt hợp tác cùng SMART BOX trong việc tạo ra không gian an toàn và thuận tiện cho người sử dụng.",
    src: "https://static.wixstatic.com/media/d0c6c1_44390e4714e24d809f0658e316215412~mv2.png/v1/fill/w_233,h_163,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ICON-09.png",
    width: "60%",
    height: "126",
  },
  {
    tilte: "Đối tác E-commerce",
    description:
      "SMART BOX mang đến thêm nhiều lựa chọn ưu việt hơn cho khách hàng, góp phần kích thích mua sắm, tiếp cận tới nhiều tệp khách hàng có nhu cầu đặc biệt của các đơn vị e-commerce.",
    src: "https://static.wixstatic.com/media/d0c6c1_0d39a0f7f7a9453e973f84fcbcd5ee1c~mv2.png/v1/fill/w_171,h_135,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ICON-13.png",
    width: "60%",
    height: "126",
  },
  {
    tilte: "Tham gia hệ thống",
    description:
      "Sở hữu tủ khóa SMART BOX của riêng bạn, sở hữu tủ khóa cá nhân tại địa điểm được yêu cầu (Dành cho đối tác tại Hà Nội và TP. Hồ Chí Minh)",
    src: "https://static.wixstatic.com/media/d0c6c1_14b8deef1af54c9fbefd1a274dca4c7e~mv2.png/v1/crop/x_481,y_0,w_2354,h_2218/fill/w_179,h_169,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ICON-27.png",
    width: "60%",
    height: "126",
  },
];

export const CONTACTS = [
  {
    icon: <UserOutlined className="icon-support" />,
    partner: "Khách hàng cá nhân",
    email: "customer@obx.vn",
  },
  {
    icon: <SolutionOutlined className="icon-support" />,
    partner: "Đối tác doanh nghiệp",
    email: "business@obx.vn",
  },
  {
    icon: <SoundOutlined className="icon-support" />,
    partner: "Đối tác truyền thông",
    email: "media@obx.vn",
  },
  {
    icon: <AuditOutlined className="icon-support" />,
    partner: "International partners",
    email: "partnership@obx.vn",
  },
  {
    icon: <AuditOutlined className="icon-support" />,
    partner: "Đối tác vận hành",
    email: "contract@obx.vn",
  },
];
