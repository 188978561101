import CardImage from "../../../components/common/CardImage";
import { Col, Row } from "antd";
import Title from "antd/es/typography/Title";
import { ADVERTISING_PARTNER, CONTACTS } from "./constants";

const AdvertisingPartner = () => {
  return (
    <div className="content">
      <Row justify="center">
        <Col xs={18} md={14}>
          <Title level={4} style={{ textAlign: "center", marginTop: 40 }}>
            ĐỐI TÁC CỦA SMART BOX
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={22} md={16}>
          <Title level={2} style={{ textAlign: "center", marginTop: -5 }}>
            Trở thành một phần trong mạng lưới đối tác đa lĩnh vực của SMART BOX
            ngay hôm nay
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24} lg={16}>
          <Row justify="center">
            {ADVERTISING_PARTNER.map((item) => (
              <Col xs={24} md={12} style={{ marginTop: 70 }}>
                <Row justify="center">
                  <CardImage
                    styles={{
                      backgroundColor: "#fffcf8",
                      borderRadius: 40,
                      boxShadow: "var(--shd,0 0px 5px rgba(0,0,0,.6))",
                    }}
                    tilte={item.tilte}
                    description={item.description}
                    width="80%"
                    height="400"
                    imageHeight={item.height}
                    imageWidth={item.width}
                    src={item.src}
                  />
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={22} md={16}>
          <Title level={2} style={{ textAlign: "center", marginTop: 70 }}>
            Liên hệ hợp tác
          </Title>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 30 }}>
        <Col span={20}>
          <Row justify="center">
            {CONTACTS.map((item) => (
              <Col xs={24} md={12} lg={4} style={{ marginTop: 25 }}>
                <Row justify="center">{item.icon}</Row>
                <Row justify="center">
                  <Title
                    level={5}
                    style={{ textAlign: "center", marginTop: 10 }}
                  >
                    {item.partner}
                  </Title>
                </Row>
                <Row justify="center">
                  <a
                    href={`mailto:${item.email}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Title
                      level={4}
                      style={{ textAlign: "center", marginTop: 0 }}
                    >
                      {item.email}
                    </Title>
                  </a>
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AdvertisingPartner;
