import { MenuProps } from "antd";
import HomePage from "../../views/landingPage/homePage";
import AboutUs from "../../views/landingPage/aboutUs";
import Product from "../../views/landingPage/product";
import AdvertisingPartner from "../../views/landingPage/advertisingPartner";
import Support from "../../views/landingPage/support";
import Policy from "../../views/landingPage/policy";

export const ROUTE_URL = {
  HOMEPAGE: "/",
  PRODUCT: "/product",
  ADVERTISING_PARTNER: "/advertisingPartner",
  SUPPORT: "/support",
  ABOUT_US: "/aboutUs",
  TERMS_OF_SERVICE: "/policy",
  PRIVACY: "/privacy",
};

export const MENU_ITEMS: MenuProps["items"] = [
  {
    label: "Trang Chủ",
    key: ROUTE_URL.HOMEPAGE,
  },
  {
    label: "Sản Phẩm",
    key: ROUTE_URL.PRODUCT,
  },
  {
    label: "Đối tác quảng cáo",
    key: ROUTE_URL.ADVERTISING_PARTNER,
  },
  {
    label: "Hỗ Trợ",
    key: ROUTE_URL.SUPPORT,
  },
  {
    label: "Về Smart Box",
    key: ROUTE_URL.ABOUT_US,
  },
];

export const HomePageRoutes = [
  {
    path: ROUTE_URL.HOMEPAGE,
    element: <HomePage />,
  },
  {
    path: ROUTE_URL.ABOUT_US,
    element: <AboutUs />,
  },
  {
    path: ROUTE_URL.PRODUCT,
    element: <Product />,
  },
  {
    path: ROUTE_URL.ADVERTISING_PARTNER,
    element: <AdvertisingPartner />,
  },
  {
    path: ROUTE_URL.SUPPORT,
    element: <Support />,
  },
  {
    path: ROUTE_URL.TERMS_OF_SERVICE,
    element: <Policy />,
  },
  {
    path: ROUTE_URL.PRIVACY,
    element: <Policy />,
  },
];
