import { CSSProperties, ReactNode } from "react";
import { Card } from "antd";
import { isString } from "antd/es/button";

const CardImage = (cardProps: CardProps) => {
  const {
    className,
    tilte,
    description,
    width,
    src,
    imageWidth,
    imageHeight,
    styles,
    componentContent,
    height,
  } = cardProps;
  const { Meta } = Card;
  const isPercent = (value: any) => {
    if (isString(value) && value.includes("%")) {
      return true;
    }
    return false;
  };
  return (
    <Card
      className={className}
      hoverable
      style={{
        height: isPercent(height) ? height : Number(height),
        width: isPercent(width) ? width : Number(width),
        textAlign: "center",
        ...styles,
      }}
      cover={
        <div>
          <img
            alt={tilte}
            src={src}
            width={
              imageWidth
                ? isPercent(imageWidth)
                  ? imageWidth
                  : Number(imageWidth)
                : Number(width)
            }
            height={
              imageHeight
                ? isPercent(imageHeight)
                  ? imageHeight
                  : Number(imageHeight)
                : Number(width)
            }
            style={imageWidth && imageHeight ? { marginTop: 25 } : {}}
          />
        </div>
      }
    >
      <Meta
        title={tilte}
        description={componentContent ? componentContent : description}
      />
    </Card>
  );
};

export default CardImage;

interface CardProps {
  className?: string;
  tilte: string;
  description?: string;
  width?: string;
  height?: string;
  src: string;
  imageWidth?: string;
  imageHeight?: string;
  styles?: CSSProperties;
  componentContent?: ReactNode;
}
