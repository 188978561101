/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Button, Col, Layout, Row } from "antd";
import MenuComponent from "../../components/common/Menu";
import { AdminRoutes, ROUTE_URL } from "../../routes/admin";
import { AdminContext } from "../../stores/contexts/AdminContext";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { UserOutlined } from "@ant-design/icons";
import "../../styles/admin/admin.css";
import LoadingContainer from "../../components/common/Loading";
import { Header } from "antd/es/layout/layout";
import logoImg from "../../images/logo.png";

export const USER_INFO_JSON_NAME = "userInfoJson";

const { Content, Footer, Sider } = Layout;

const AdminPage = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [userInfo, setUserInfor] = useState<any>(null);
  const userInfoJson = sessionStorage.getItem(USER_INFO_JSON_NAME);
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      navigate(ROUTE_URL.HOME);
    } else {
      navigate(ROUTE_URL.LOGIN);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfoJson) {
      const dataUser = JSON.parse(userInfoJson);
      setUserInfor(dataUser);
    }
  }, [userInfoJson]);

  return (
    <AdminContext.Provider value={{ userInfo, setUserInfor, setLoadingPage }}>
      <Layout style={{ minHeight: "100vh" }} className="admin-container">
        {!isEmpty(userInfo) && (
          <Sider
            width={200}
            style={{
              backgroundColor: "#ffffff",
              borderRight: "1px solid rgb(239, 242, 247)",
            }}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
          >
            <Row
              style={{ height: 64, display: "flex", alignItems: "center" }}
              justify="center"
            >
              <img src={logoImg} alt="logo" width="50" height="50" />
            </Row>
            <MenuComponent />
          </Sider>
        )}
        <Layout>
          {!isEmpty(userInfo) && (
            <Header style={{ backgroundColor: "#ffffff", padding: 0 }}>
              <Row justify="end" style={{ height: 64 }}>
                <Fragment>
                  <Row justify="center" style={{ height: 64 }}>
                    <UserOutlined
                      style={{
                        fontSize: 40,
                        color: "#3a80c8",
                      }}
                    />
                  </Row>
                  <Row style={{ height: 64 }}>
                    <Col
                      span={24}
                      style={{
                        height: 32,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Row
                        justify="center"
                        style={{
                          fontSize: 17,
                          color: "#3a80c8",
                        }}
                      >
                        {userInfo.name}
                      </Row>
                    </Col>
                    <Col span={24} style={{ height: 32 }}>
                      <Row justify="center">
                        <Button
                          size="small"
                          danger
                          onClick={() => {
                            setUserInfor(null);
                            sessionStorage.removeItem(USER_INFO_JSON_NAME);
                            toast.info("Logged out", { autoClose: 2000 });
                          }}
                        >
                          Log out
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </Fragment>
              </Row>
            </Header>
          )}
          <Content style={{ margin: 20 }}>
            <Routes>
              {AdminRoutes.map((item) => (
                <Route path={item.path} element={item.element} />
              ))}
            </Routes>
          </Content>
          <Footer style={{ textAlign: "center", height: 100 }}>
            SMART BOX ©2024
          </Footer>
        </Layout>
        {loadingPage && <LoadingContainer />}
      </Layout>
    </AdminContext.Provider>
  );
};

export default AdminPage;
