import axiosClient from "./axiosCilent";

const bannerApi = {
  addBanner: (params: any) => {
    const url = "/api/v1/post-banner";
    return axiosClient.post(url, params);
  },
  getBanners: () => {
    const url = "/api/v1/get-banner";
    return axiosClient.get(url);
  },
  deleteBanner: (params: any) => {
    const url = "/api/v1/delete-banner";
    return axiosClient.post(url, params);
  },
  updateBanner: (params: any) => {
    const url = "/api/v1/update-banner";
    return axiosClient.post(url, params);
  },
};

export default bannerApi;
