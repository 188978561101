import { Col, Row } from "antd";
import "../../../styles/support.css";
import Title from "antd/es/typography/Title";
import {
  ACCOUNT_MANAGEMENT,
  CHANGES_TO_TERMS_OF_SERVICE,
  COMPENSATION_IN_THE_CASES,
  COMPLAINTS_AND_CLAIMS,
  CREATE_SMART_APP_ACCOUNT,
  DEFINE,
  GENERAL_RULE,
  HANDLING_OVERDUE_PACKAGES,
  INFORMATION_MANAGE,
  OTHER_REGULATIONS_ON_USE_OF_THE_SERVICE,
  OWNERSHIP_OF_INTELLECTUAL_PROPERTY,
  PRICE_TO_USE_THE_SERVICE,
  SEND_NOTIFICATION,
  SERVICE_PROVISION_TERM,
  SMART_APP_LOCKER_SERVICE,
  SMART_APP_STRICTLY_PROHIBITED,
  SUSPENSION_INTERRUPTION_TERMINATION,
  USER_INFORMATION,
} from "./constant";

const Policy = () => {
  return (
    <div className="content">
      <Row justify="center">
        <Title>ĐIỀU KHOẢN SỬ DỤNG</Title>
        <Col xs={18}>
          <p>
            Điều khoản sử dụng dịch vụ này do Công ty cổ phần SMART APP Express
            ban hành và áp dụng cho Người dùng sử dụng Dịch vụ Tủ khóa thông
            minh SMART APP và các dịch vụ tiện ích gia tăng khác. Người sử dụng
            Dịch vụ Tủ khóa thông minh SMART APP cần đọc và đồng ý với những
            điều khoản dịch vụ này trước khi sử dụng Dịch vụ.
          </p>
          <p>
            Để bảo vệ quyền và lợi ích của mình, Công ty cổ phần SMART APP
            Express khuyến nghị Người sử dụng dịch vụ đọc kỹ và hiểu nội dung
            Điều khoản sử dụng dịch vụ này trước khi sử dụng Dịch vụ. Nếu người
            sử dụng dịch vụ có bất kỳ câu hỏi nào về nội dung của Điều khoản sử
            dụng dịch vụ này, vui lòng liên hệ Bộ phận chăm sóc khách hàng của
            Công ty cổ phần SMART APP Express. Trường hợp không chấp nhận bất kỳ
            nội dung nào của Điều khoản sử dụng dịch vụ này hoặc bất kỳ nội dung
            nào của các chính sách, điều khoản sử dụng dịch vụ khác đã được công
            bố công khai bởi Công ty cổ phần SMART APP Express liên quan đến
            dịch vụ được cung cấp, Người sử dụng cần ngừng sử dụng Dịch vụ.
          </p>
          <Title level={4}>I. Quy tắc chung</Title>
          <ol>
            {GENERAL_RULE.map((ele) => (
              <li>{ele}</li>
            ))}
          </ol>
          <Title level={4}>II. Định nghĩa</Title>
          <ol>
            {DEFINE.map((ele) => (
              <li>{ele}</li>
            ))}
          </ol>
          <Title level={4}>III. Tài khoản và quản lý Tài khoản SMART APP</Title>
          <ol>
            <li>
              Tạo Tài khoản SMART APP
              <ol>
                {CREATE_SMART_APP_ACCOUNT.map((ele) => (
                  <li>{ele}</li>
                ))}
              </ol>
            </li>
            <li>
              Thông tin Người sử dụng
              <ol>
                {USER_INFORMATION.map((ele) => (
                  <li>{ele}</li>
                ))}
              </ol>
            </li>
            <li>
              Số lượng tài khoản
              <p>
                Với mỗi số điện thoại, Người sử dụng chỉ có thể đăng ký một Tài
                khoản SMART APP.
              </p>
            </li>
            <li>
              Quyền đối với tài khoản
              <p>
                Người sử dụng hiểu rằng SMART APP Express cung cấp nhiều Dịch
                vụ. Sau khi Người sử dụng có được Tài khoản SMART APP, điều đó
                không có nghĩa là Người sử dụng có thể sử dụng tất cả các Dịch
                vụ/sản phẩm của SMART APP. Người sử dụng phải đáp ứng các điều
                kiện sử dụng Dịch vụ/sản phẩm tương ứng chỉ sau khi thực hiện
                xác nhận/cung cấp thông tin Người sử dụng mới có thể tiếp tục sử
                dụng Dịch vụ.
              </p>
            </li>
            <li>
              Sử dụng tài khoản
              <p>
                Tài khoản SMART APP của Người sử dụng sẽ được liên kết với thông
                tin cá nhân, giao dịch và các thông tin khác của Người sử dụng.
                Do đó, tài khoản SMART APP chỉ có thể được sử dụng bởi Người sử
                dụng.
              </p>
            </li>
            <li>
              Quản lý tài khoản
              <ol>
                {ACCOUNT_MANAGEMENT.map((ele) => (
                  <li>{ele}</li>
                ))}
              </ol>
            </li>
            <li>
              Hủy tài khoản.
              <p>
                Người sử dụng có thể hủy tài khoản của mình qua ứng dụng, wesite
                dịch vụ dành cho Khách hàng hoặc liên hệ với SMART APP theo quy
                trình hủy tài khoản.
              </p>
            </li>
          </ol>
          <Title level={4}>
            IV. Nội dung và phạm vi cung ứng Dịch vụ tủ khóa thông minh SMART
            APP
          </Title>
          <ol>
            <li>
              Dịch vụ tủ khóa thông minh SMART APP
              <ol>
                {SMART_APP_LOCKER_SERVICE.map((ele, index) => {
                  if (index === SMART_APP_LOCKER_SERVICE.length - 1) {
                    return (
                      <li>
                        {ele}
                        <ol>
                          <li>
                            Ngừng cung cấp Dịch vụ ngay lập tức và loại bỏ Gói
                            hàng khỏi Ngăn tủ.
                          </li>
                          <li>
                            Xử lý, tiêu hủy, vứt bỏ, … các hàng hóa/vật phẩm
                            thuộc Danh mục hàng hóa cấm mà SMART APP xét thấy
                            cần phải xử lý ngay lập tức.
                          </li>
                          <li>
                            Báo tin, thông báo phát hiện hành vi/dấu hiệu tội
                            phạm, giao nộp cho cơ quan nhà nước có thẩm quyền,
                            yêu cầu cơ quan nhà nước có thẩm quyền xử lý, … các
                            hàng hóa/vật phẩm thuộc Danh mục hàng hóa cấm mà
                            SMART APP xét thấy cần phải thực hiện báo tin, thông
                            báo, giao nộp hàng hóa/vật phẩm có dấu hiệu phạm tội
                            đến cơ quan nhà nước có thẩm quyền.
                          </li>
                          <li>
                            Xử lý các Gói hàng được đóng gói không đúng quy
                            cách, sử dụng sai kích thước Ngăn tủ, quá trọng
                            lượng tải của Ngăn tủ gây ảnh hưởng đến tính toàn
                            vẹn, chức năng hoạt động bình thường của Ngăn tủ/Tủ
                            SMART APP.
                          </li>
                        </ol>
                      </li>
                    );
                  }
                  return <li>{ele}</li>;
                })}
              </ol>
            </li>
            <li>
              Giá sử dụng dịch vụ
              <ol>
                {PRICE_TO_USE_THE_SERVICE.map((ele) => (
                  <li>{ele}</li>
                ))}
              </ol>
            </li>
            <li>
              Thời hạn cung cấp Dịch vụ
              <ol>
                {SERVICE_PROVISION_TERM.map((ele) => (
                  <li>{ele}</li>
                ))}
              </ol>
            </li>
            <li>
              Xử lý Gói hàng quá hạn
              <ol>
                {HANDLING_OVERDUE_PACKAGES.map((ele) => (
                  <li>{ele}</li>
                ))}
              </ol>
            </li>
            <li>
              Cam kết dịch vụ
              <p>
                Người sử dụng hiểu và đồng ý rằng SMART APP sẽ thực hiện cung
                cấp Dịch vụ ở mức hợp lý về mặt thương mại. Những thông tin do
                đối tác của SMART APP cam kết cụ thể về Dịch vụ, các thỏa thuận
                liên quan và các quy tắc do đối tác ký không được SMART APP ưu
                tiên áp dụng khi xảy ra tranh chấp khiếu nại giữa các bên liên
                quan. SMART APP không đưa ra bất kỳ cam kết cụ thể nào đối với
                Người sử dụng về Dịch vụ.
              </p>
            </li>
            <li>
              Tạm dừng, gián đoạn và chấm dứt Dịch vụ
              <ol>
                {SUSPENSION_INTERRUPTION_TERMINATION.map((ele) => (
                  <li>{ele}</li>
                ))}
              </ol>
              <p>
                Các lỗi trên không được xem là lỗi do SMART APP gây nên và SMART
                APP không có trách nhiệm bồi thường trong mọi trường hợp trên.
              </p>
            </li>
            <li>
              Quy định khác về sử dụng Dịch vụ
              <ol>
                {OTHER_REGULATIONS_ON_USE_OF_THE_SERVICE.map((ele) => (
                  <li>{ele}</li>
                ))}
              </ol>
            </li>
            <li>
              Thông tin và quản lý thông tin Người sử dụng
              <ol>
                <li>
                  Thông tin cá nhân
                  <p>
                    SMART APP coi trọng việc bảo vệ thông tin cá nhân, khi Người
                    sử dụng sử dụng dịch vụ của SMART APP, Người sử dụng đồng ý
                    rằng SMART APP sẽ thu thập, sử dụng, chia sẻ, lưu trữ và bảo
                    vệ thông tin cá nhân của Người sử dụng theo Chính sách bảo
                    mật và quyền riêng tư (“Chính sách bảo mật”) do SMART APP
                    quy định về thông tin khách hàng. Vui lòng đọc chi tiết
                    “Chính sách Bảo mật”.
                  </p>
                </li>
                <li>
                  Thông tin phi cá nhân
                  <p>
                    Đối với văn bản, hình ảnh, tài liệu nghe nhìn và các thông
                    tin khác ngoài thông tin cá nhân được hình thành trong quá
                    trình Người sử dụng đăng ký tài khoản SMART APP hoặc sử dụng
                    các dịch vụ của SMART APP, Người sử dụng đồng ý cho phép
                    SMART APP sử dụng trong thời gian bảo vệ theo quy định của
                    pháp luật. Và có thể thu thập bằng chứng hoặc kiện tụng
                    chống lại hành vi xâm phạm của bên thứ ba dưới danh nghĩa
                    của chính mình.
                  </p>
                </li>
                <li>
                  Thông tin bị cấm
                  <p>
                    Người sử dụng cam kết và đảm bảo rằng thông tin hình thành
                    trong quá trình sử dụng các Dịch vụ của SMART APP sẽ không
                    vi phạm những điều dưới đây:
                  </p>
                  <ol>
                    <li>Vi phạm các quy định của pháp luật.</li>
                    <li>
                      SMART APP nghiêm cấm mọi hành vi sử dụng Dịch vụ vào các
                      mục đích sau:
                      <ol>
                        {SMART_APP_STRICTLY_PROHIBITED.map((ele) => (
                          <li>{ele}</li>
                        ))}
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  Quản lý thông tin
                  <ol>
                    {INFORMATION_MANAGE.map((ele) => (
                      <li>{ele}</li>
                    ))}
                  </ol>
                </li>
                <li>
                  Thông số kỹ thuật bảo vệ Tủ (Hộp) khóa thông minh SMART APP.
                  <ol>
                    <li>
                      Người sử dụng hiểu rằng các hiển thị trực quan như màu
                      sắc, hoa văn và logo thương hiệu của tủ (hộp) khóa thông
                      minh SMART APP là yếu tố quan trọng để phân biệt nguồn gốc
                      của dịch vụ, nhận diện thương hiệu của nhà cung cấp Dịch
                      vụ. Do đó, Người sử dụng không được tự ý thay đổi, gây hư
                      hại bề mặt và cấu trúc của tủ (hộp) khóa thông minh SMART
                      APP.
                    </li>
                    <li>
                      SMART APP là người sở hữu Tủ khóa thông minh SMART APP.
                      Người sử dụng không được thực hiện những điều sau đây, và
                      có trách nhiệm bồi thường trong các trường hợp sau (bao
                      gồm nhưng không hạn chế):
                      <ol>
                        {COMPENSATION_IN_THE_CASES.map((ele) => (
                          <li>{ele}</li>
                        ))}
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  Điều khoản miễn trừ
                  <ol>
                    <li>
                      Trường hợp bất khả kháng
                      <p>
                        Người sử dụng đồng ý rằng SMART APP sẽ cung cấp dịch vụ
                        theo đúng quy định tại các Chính sách của SMART APP cũng
                        như quy định pháp luật hiện hành, SMART APP sẽ không
                        chịu trách nhiệm về những thiệt hại gây ra cho Người sử
                        dụng bởi sự kiện bất khả kháng, thiệt hại do đặc tính tự
                        nhiên/khuyết tật vốn có của Gói hàng, chính quyền hoặc
                        các bên thứ ba.
                      </p>
                    </li>
                    <li>
                      Miễn trừ trách nhiệm
                      <p>
                        SMART APP sẽ được miễn trừ mọi trách nhiệm nếu tổn thất
                        gây ra do lỗi của người sử dụng, bao gồm nhưng không
                        giới hạn việc đặt Gói hàng vào Tủ khóa thông minh SMART
                        APP mà không có sự đồng ý của người nhận, không thao tác
                        theo đúng hướng dẫn sử dụng, không sử dụng đúng tài
                        khoản hoặc thiết bị di động đã được định danh của Người
                        sử dụng, Người sử dụng giao hoặc để người khác (cố ý/vô
                        ý) sử dụng tài khoản của Người sử dụng để sử dụng Dịch
                        vụ, không tuân thủ quy cách Gói hàng và/hoặc không tuân
                        thủ các điều kiện và chính sách cung ứng dịch vụ khác
                        của SMART APP.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  Sở hữu trí tuệ
                  <ol>
                    <li>
                      Quyền sở hữu tài sản trí tuệ
                      <ol>
                        {OWNERSHIP_OF_INTELLECTUAL_PROPERTY.map((ele) => (
                          <li>{ele}</li>
                        ))}
                      </ol>
                    </li>
                    <li>
                      Xử lý vi phạm
                      <p>
                        Người dùng áp dụng mọi biện pháp cần thiết theo quy định
                        pháp luật để bảo vệ quyền sở hữu trí tuệ của SMART APP
                        (bao gồm nhưng không hạn chế biện pháp về tố tụng dân
                        sự, tố tụng hình sự, …)
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  Thông báo
                  <ol>
                    <li>
                      Thông tin liên hệ
                      <p>
                        SMART APP cung cấp cho Người sử dụng thông báo khác nhau
                        thông qua thông tin liên hệ mà Người sử dụng cung cấp,
                        thông báo này có thể liên quan đến các quyền và nghĩa vụ
                        quan trọng của Người sử dụng. Người sử dụng chịu trách
                        nhiệm về tính xác thực và hợp lệ của thông tin liên hệ
                        đã cung cấp, nếu thông tin liên hệ thay đổi, Người sử
                        dụng có trách nhiệm cập nhật thông tin kịp thời để đảm
                        bảo SMART APP có thể liên hệ được với Người sử dụng.
                      </p>
                    </li>
                    <li>
                      Gửi thông báo
                      <ol>
                        {SEND_NOTIFICATION.map((ele) => (
                          <li>{ele}</li>
                        ))}
                      </ol>
                    </li>
                    <li>
                      Thay đổi Điều khoản sử dụng dịch vụ
                      <ol>
                        {CHANGES_TO_TERMS_OF_SERVICE.map((ele) => (
                          <li>{ele}</li>
                        ))}
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  Giải quyết tranh chấp
                  <p>
                    Nội dung Điều khoản sử dụng dịch vụ này được hiểu và điều
                    chỉnh theo luật pháp của nước Cộng hòa xã hội chủ nghĩa Việt
                    Nam.
                  </p>
                  <p>
                    Các tranh chấp phát sinh từ việc sử dụng Dịch vụ và/hoặc các
                    tranh chấp liên quan đến dịch vụ cần được giải quyết thông
                    qua tham vấn giữa hai bên một cách kịp thời; nếu tham vấn
                    không thành công, một trong hai bên có quyền khởi kiện tại
                    Tòa án có thẩm quyền.
                  </p>
                </li>
                <li>
                  Khiếu nại và yêu cầu bồi thường
                  <ol>
                    {COMPLAINTS_AND_CLAIMS.map((ele) => (
                      <li>{ele}</li>
                    ))}
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </Col>
      </Row>
    </div>
  );
};

export default Policy;
