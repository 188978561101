import { useEffect, useState } from "react";
import type { MenuProps } from "antd";
import { Col, Menu, Row } from "antd";
import { MENU_ITEMS, ROUTE_URL } from "../../routes/landing";
import { useLocation, useNavigate } from "react-router-dom";
import logoImg from "../../images/logo.png";

const Header = () => {
  const [current, setCurrent] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const onClick: MenuProps["onClick"] = (e) => {
    navigate(`${e.key}`);
  };

  const centerStyle = {
    backgroundColor: "#fffcf8",
    border: "none",
  };

  useEffect(() => {
    const { pathname, state } = location;
    const isMoveToQr = state?.isMoveToQr;
    setCurrent(pathname);
    if (!isMoveToQr) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [location]);

  return (
    <Row>
      <Col span={5}></Col>
      <Col span={4}>
        <img
          src={logoImg}
          alt="logo"
          width="46"
          height="46"
          onClick={() => navigate(ROUTE_URL.HOMEPAGE)}
        />
      </Col>
      <Col span={15}>
        <Menu
          style={centerStyle}
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={MENU_ITEMS}
        />
      </Col>
    </Row>
  );
};

export default Header;
