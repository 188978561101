import { Card, Carousel, Col, Image, Row } from "antd";
import Title from "antd/es/typography/Title";
import {
  IMAGE_LINK_OUTSTANDING_FEATURES,
  OUTSTANDING_FEATURES,
} from "./constants";
import "../../../styles/product.css";
import { CSSProperties, useEffect, useRef } from "react";
import CardImage from "../../../components/common/CardImage";
import { useLocation } from "react-router-dom";

const flexColumnStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const Product = () => {
  const location = useLocation();
  const qrRef: any = useRef(null);
  useEffect(() => {
    const { state } = location;
    const isMoveToQr = state?.isMoveToQr;
    if (isMoveToQr && qrRef.current) {
      const x = qrRef.current.offsetLeft || 0;
      const y = qrRef.current.offsetTop || 0;
      window.scrollTo({
        top: y - 40,
        left: x,
        behavior: "smooth",
      });
    }
  }, [location]);
  return (
    <div className="content-product-page">
      <Image
        width="100%"
        src="https://static.wixstatic.com/media/d0c6c1_6e07f1cad9e14124b87ea453034f311d~mv2.png/v1/fill/w_1891,h_456,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/d0c6c1_6e07f1cad9e14124b87ea453034f311d~mv2.png"
      />
      <Row style={{ marginTop: 30 }} justify="center">
        <Col xs={20} md={14}>
          <Title level={2} style={{ textAlign: "center", marginTop: 15 }}>
            TỦ KHÓA THÔNG MINH SMART BOX
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={18} md={10}>
          <Title level={5} style={{ textAlign: "center", marginTop: -10 }}>
            Điểm trung gian tiếp nhận, tạm thời lưu trữ và bảo quản hàng hóa
          </Title>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 50 }}>
        <Col xs={22} md={20} lg={18}>
          <Row>
            <Col
              md={{
                order: 1,
                span: 12,
              }}
              xs={{
                order: 2,
                span: 24,
              }}
              style={{ paddingLeft: 50, paddingRight: 50 }}
            >
              {OUTSTANDING_FEATURES.map((item) => (
                <Card
                  hoverable
                  style={{
                    marginBottom: 10,
                    height: 85,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontSize: 16 }}>{item}</span>
                </Card>
              ))}
            </Col>
            <Col
              md={{
                order: 2,
                span: 12,
              }}
              xs={{
                order: 1,
                span: 24,
              }}
              style={{ marginBottom: 30 }}
            >
              <Carousel autoplay>
                {IMAGE_LINK_OUTSTANDING_FEATURES.map((src, index) => (
                  <img
                    src={src}
                    alt={`outstandingFeaturesImage_${index + 1}`}
                    height="366"
                  />
                ))}
              </Carousel>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center" ref={qrRef} style={{ marginTop: 30 }}>
        <Col span={18}>
          <Row justify="center" align="middle">
            <Col lg={12} xs={24}>
              <Row justify="center">
                <Image
                  style={{ height: 500, width: 300 }}
                  src="https://static.wixstatic.com/media/d0c6c1_bbc5126119874291b3747d0692ce5686~mv2.gif"
                />
              </Row>
            </Col>
            <Col lg={12} xs={24}>
              <Row justify="center">
                <Title level={2} style={{ textAlign: "center" }}>
                  ỨNG DỤNG SMART BOX
                </Title>
                <Title level={5} style={{ marginTop: 0, textAlign: "center" }}>
                  Tích hợp đầy đủ các tính năng hỗ trợ người dùng (người gửi,
                  người nhận) trong việc sử dụng các dịch vụ của ONEBOX và tương
                  tác với tủ khóa thông minh
                </Title>
                <Row>
                  <Col md={12} xs={24}>
                    <Row justify="center">
                      <Image
                        style={{ height: 178, width: 178 }}
                        src="https://static.wixstatic.com/media/d0c6c1_781d4fa47683488a8c04d9e90bed78e3~mv2.png/v1/fill/w_178,h_178,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/QR_Code_Download_App_ONEBOX.png"
                      />
                    </Row>
                  </Col>
                  <Col md={12} xs={24} style={flexColumnStyle}>
                    <Col span={12} style={{ ...flexColumnStyle }}>
                      <Image
                        style={{ height: 66, width: 226 }}
                        src="https://static.wixstatic.com/media/d0c6c1_111f96bbbbe740e38ff48d0bb2fa740b~mv2.png/v1/fill/w_227,h_66,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/googleplay.png"
                      />
                    </Col>
                    <Col span={12} style={{ ...flexColumnStyle }}>
                      <Image
                        style={{ height: 66, width: 226 }}
                        src="https://static.wixstatic.com/media/d0c6c1_bab49d20eda04f9f95a780c535e6be04~mv2.png/v1/fill/w_226,h_66,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/appstore.png"
                      />
                    </Col>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 70, textAlign: "center" }}>
        <Col span={22}>
          <Title level={2}>DỊCH VỤ CỦA SMART BOX</Title>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 70 }}>
        <Col span={16}>
          <Row justify="space-between">
            <Col xs={24} md={11}>
              <CardImage
                styles={{
                  backgroundColor: "#fffcf8",
                  borderRadius: 20,
                  boxShadow: "var(--shd,0 0px 5px rgba(0,0,0,.6))",
                  textAlign: "left",
                  marginBottom: 20,
                }}
                height="590"
                tilte="Gửi hàng"
                width="100%"
                imageWidth="100%"
                src="https://static.wixstatic.com/media/d0c6c1_5a5aac31b41049158bd90cbdbf0c239b~mv2.png/v1/fill/w_468,h_281,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Asset_164x.png"
                componentContent={
                  <p>
                    ✔ Chủ động thời gian và địa điểm gửi hàng
                    <br /> ✔ Tiết kiệm thời gian chờ đợi người nhận xuống lấy
                    hàng
                    <br />✔ Tiết kiệm chi phí xăng xe, điện thoại khi gửi hàng
                    cho khách
                    <br /> ✔ Khuyến khích thanh toán trực tuyến, giảm thiểu tỷ
                    lệ từ chối nhận hàng, hoàn hàng
                    <br /> ✔ Sở hữu chính sách bảo hiểm hàng hóa và quyền lợi
                    người sử dụng
                  </p>
                }
              />
            </Col>
            <Col span={2}></Col>
            <Col xs={24} md={11}>
              <CardImage
                styles={{
                  backgroundColor: "#fffcf8",
                  borderRadius: 20,
                  boxShadow: "var(--shd,0 0px 5px rgba(0,0,0,.6))",
                  textAlign: "left",
                  marginBottom: 20,
                }}
                height="590"
                tilte="Nhận hàng"
                width="100%"
                imageWidth="100%"
                src="https://static.wixstatic.com/media/d0c6c1_9317d57436e84bfaa29e3cc77e3bef2d~mv2.png/v1/fill/w_468,h_281,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Asset_174x.png"
                componentContent={
                  <p>
                    ✔ Chủ động lựa chọn địa điểm, thời gian nhận hàng
                    <br /> ✔ Tăng cường bảo mật thông tin, không cần tiết lộ
                    công khai địa chỉ cá nhân
                    <br /> ✔ Không cần nghe điện thoại hay gặp trực tiếp
                    shipper. Mọi giao dịch đều được thực hiện ngay trên ứng dụng
                    hoặc tại tủ khóa SMART BOX
                    <br /> ✔ Đảm bảo tính riêng tư về thông tin hàng hóa
                    <br /> ✔ Sở hữu chính sách bảo hiểm hàng hóa và quyền lợi
                    người sử dụng
                  </p>
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Product;
