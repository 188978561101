import { Col, Image, Row } from "antd";
import Title from "antd/es/typography/Title";
import logoImg from "../../images/logo.png";

const LINK_GROUP_ONE = [
  "Về SMART BOX",
  "Điều khoản sử dụng",
  "Chính sách bảo mật",
  "Danh mục hàng hoá cấm",
  "Chính sách bồi thường",
];

const LINK_GROUP_TWO = [
  {
    title: "Khách hàng cá nhân",
    email: "customer@obx.vn",
  },
  {
    title: "Đối tác doanh nghiệp",
    email: "business@obx.vn",
  },
  {
    title: "Đối tác truyền thông",
    email: "media@obx.vn",
  },
  {
    title: "Đối tác vận hành",
    email: "contract@obx.vn",
  },
  {
    title: "International partners",
    email: "partnership@obx.vn",
  },
];

const Footer = () => {
  return (
    <Row justify="space-between">
      <Col xs={24} md={8}>
        <Row justify="center">
          <Col span={24}>
            <Row justify="center">
              <Image style={{ width: "100%" }} src={logoImg} />
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Title level={5} style={{ textAlign: "center" }}>
                © 2024 by SMART BOX
              </Title>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={8}>
        <Row justify="center" style={{ marginTop: 25 }}>
          {LINK_GROUP_ONE.map((item) => (
            <Col span={24}>
              <Row justify={"center"}>
                <a href="/" target="_blank">
                  <Title level={5} style={{ textAlign: "center" }}>
                    {item}
                  </Title>
                </a>
              </Row>
            </Col>
          ))}
        </Row>
      </Col>
      <Col xs={24} md={8} style={{ marginTop: 25 }}>
        <Row justify="center">
          <Col span={24}>
            <Title level={4} style={{ textAlign: "center", marginBottom: -20 }}>
              LIÊN HỆ HỢP TÁC
            </Title>
          </Col>
          {LINK_GROUP_TWO.map((item) => (
            <Col span={24} style={{ textAlign: "center", marginBottom: -20 }}>
              <Title level={5} style={{ textAlign: "center" }}>
                {item.title}
              </Title>
              <a href={`mailto:${item.email}`} target="_blank" rel="noreferrer">
                <Title
                  level={4}
                  style={{ textAlign: "center", marginTop: -10 }}
                >
                  {item.email}
                </Title>
              </a>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default Footer;
