import axiosClient from "./axiosCilent";

const userApi = {
  login: (params: any) => {
    const url = "/api/v1/login";
    return axiosClient.post(url, params);
  },
  getListUser: () => {
    const url = "/api/v1/get-list-user";
    return axiosClient.get(url);
  },
  addUser: (params: any) => {
    const url = "/api/v1/register";
    return axiosClient.post(url, params);
  },
  updateUser: (params: any) => {
    const url = "/api/v1/update-profile";
    return axiosClient.post(url, params);
  },
};

export default userApi;
