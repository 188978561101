export const OUTSTANDING_FEATURES = [
  "Màn hình cảm ứng LCD 21 inch hiển thị rõ nét, dễ dàng thao tác",
  "Hệ thống 105 ngăn tủ đa dạng kích cỡ, được mã hóa bảo mật",
  "“Mắt thần” quét thông tin đơn hàng và mã QR trên ứng dụng SMART BOX",
  "Camera toàn cảnh 360 độ giám sát hoạt động xung quanh tủ",
];

export const IMAGE_LINK_OUTSTANDING_FEATURES = [
  "https://static.wixstatic.com/media/d0c6c1_4fd4c44bdba346b9855530ace3ff8975~mv2.png/v1/fill/w_720,h_500,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/d0c6c1_4fd4c44bdba346b9855530ace3ff8975~mv2.png",
  "https://static.wixstatic.com/media/d0c6c1_08176a1f67944140bba502a4e5961cc6~mv2.png/v1/fill/w_720,h_500,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/d0c6c1_08176a1f67944140bba502a4e5961cc6~mv2.png",
  "https://static.wixstatic.com/media/d0c6c1_eb7cc7a525f448c08e91f9bc98b851ca~mv2.png/v1/fill/w_720,h_500,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/d0c6c1_eb7cc7a525f448c08e91f9bc98b851ca~mv2.png",
  "https://static.wixstatic.com/media/d0c6c1_02a5b41da5684a41bd61b90c88352306~mv2.png/v1/fill/w_720,h_500,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/d0c6c1_02a5b41da5684a41bd61b90c88352306~mv2.png",
];
